/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  message,
  Empty,
  notification,
  Button,
  Input,
} from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Moment from "react-moment";
import bell from "../../assests/images/bell.svg";
import clock from "../../assests/images/timerIcon.svg";
import appLogo from "../../assests/images/smcsLogo.svg";
import mobileLogo from "../../assests/images/shree-maruti-logo.svg";
import calender from "../../assests/images/calenderIcon.svg";
import checkMark from "../../assests/images/SVG/check.svg";
import hamIcon from "../../assests/images/maruti/hamburgerIcon.svg";
import mail from "../../assests/images/mail.svg";
import cart from "../../assests/images/cart.svg";
import urls from "../../api/urls";
import API from "../../api";
const { VIEW_NOTIFICATION, GET_CART_DETAILS } = urls;
import "./style.scss";
import { APIs } from "../../utils/worker";
const { baseURL } = APIs;
import { Link } from "react-router-dom";

const AppHeader = ({ showLogo, handleOpenDrawer = () => {} }) => {
  const navigate = useNavigate();
  const [dateToFormat, setdateToFormat] = useState(new Date());
  const [userName, setUserName] = useState();
  const [cartDetails, setCartDetails] = useState(0);
  const [items, setItems] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  let eventSource = undefined;

  const [api, contextHolder] = notification.useNotification();
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));

  const getRole = userinfo?.roles[0]?.replace(/.*_/, "");

  useEffect(() => {
    //initialize();
  }, []);

  const getCartDetails = async () => {
    let response = await API.Api(GET_CART_DETAILS).getWithUserNPremiseHeader();
    if (response.status) {
      localStorage.setItem(
        "cartDetails",
        JSON.stringify(response?.response?.data?.items.length)
      );
      setCartDetails(response?.response?.data?.items.length);
    }
  };

  let userid = JSON.parse(localStorage.getItem("userId"));
  let item0List = [
    {
      label: (
        <div className="space-between">
          <span className="fw-600">Notifications</span>
          <img src={mail} />
        </div>
      ),
      key: "0",
    },
    { type: "divider" },
  ];

  const viewAllNotification = (data) => {
    let tempId = [];
    data.map((item) => !item.viewed && tempId.push(item.notificationId));
    viewNotification(tempId, tempId.length > 0 ? false : true);
  };

  const initialize = async () => {
    eventSource = new EventSource(
      `${baseURL}/notification-service/v1/stream-flux/${userid?.userInfo?.userId}`
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setNotificationList(data);
      data.sort(
        (a, b) =>
          a.viewed.toString().charCodeAt(0) - b.viewed.toString().charCodeAt(0)
      );
      let item = [];
      let item1 = {};
      if (data.length > 0) {
        data.map((e, i) => {
          if (i < 4) {
            item.push(
              {
                label: (
                  <div
                    onClick={() =>
                      viewNotification([e.notificationId], e.viewed)
                    }
                  >
                    <span
                      style={{ color: "#202124" }}
                      className="space-between align-center"
                    >
                      <span>{e.notificationTitle}</span>

                      <span
                        className="notifiaction-viewed"
                        style={{
                          background: e.viewed ? "#D2D2D2" : "#EC3E3E",
                        }}
                      ></span>
                    </span>
                    <span style={{ marginTop: "-1rem", color: "#878787" }}>
                      {e.notificationCreatedDate}
                    </span>
                  </div>
                ),
                key: e.id,
              },
              { type: "divider" }
            );
          }
        });
        item1 = {
          label: (
            <span
              className="center"
              style={{ color: "#2E77D0" }}
              onClick={() => viewAllNotification(data)}
            >
              View all Notifications
            </span>
          ),
          key: "notification",
        };
      } else {
        item.push({ label: <Empty />, key: "empty" });
      }

      setItems([...item0List, ...item, item1]);
    };
    eventSource.onerror = (err) => {
      eventSource.close();
    };

    //}
  };

  const viewNotification = async (id, viewed) => {
    if (viewed) {
      navigate("/notification");
    } else {
      let response = await API.Api(VIEW_NOTIFICATION).putIdHeader({
        notificationId: id,
      });
      if (response?.status) {
        navigate("/notification", { state: id });
      } else {
        message.error("Network error.");
      }
    }
  };

  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );
  const { result: profileDetailsResult } = profileDetails;

  useEffect(() => {
    if (profileDetailsResult) {
      setUserName(profileDetailsResult?.data.firstName);
    }
  }, [profileDetailsResult]);

  useEffect(() => {
    let userName = JSON.parse(localStorage.getItem("userName"));
    setUserName(userName);
  }, [localStorage.getItem("userName")]);

  useEffect(() => {
    setInterval(() => {
      let cartDetails = JSON.parse(localStorage.getItem("cartDetails"));
      setCartDetails(cartDetails ? cartDetails : 0);
    }, 1000);
  }, [localStorage.getItem("cartDetails")]);

  useEffect(() => {
    setInterval(() => setdateToFormat(new Date()), 30000);
  }, []);

  const menuClicked = ({ key }) => {
    if (+key === 4) {
      //window.location.assign("/");
      window.location.replace("/");
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = "userToken=";
    }
    if (+key === 2) {
      navigate("/profile");
    }
  };

  const menu = (
    <Menu
      className="nav-dropdown-a"
      onClick={menuClicked}
      items={[
        {
          label: userName,
          key: "1",
          style: { fontSize: "14px" },
        },
        {
          type: "divider",
        },
        {
          label: "Profile",
          key: "2",
          style: { fontSize: "14px" },
          icon: <UserOutlined />,
          disabled: false,
        },
        {
          label: "Settings",
          key: "3",
          style: { fontSize: "14px" },
          icon: <SettingOutlined />,
          disabled: true,
        },
        {
          label: "Logout",
          key: "4",
          style: { fontSize: "14px" },
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  function MyComponent() {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 15);
    };
    return <Moment filter={toUpperCaseFilter}>{dateToFormat}</Moment>;
  }

  let time = dateToFormat.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  const openNotification = (placement) => {
    api.info({
      message: <span style={{ color: "red" }}>Extend E-way validity</span>,
      description: (
        <span>
          E-way Bill validity extension is pending. Please{" "}
          <Link to="/extend-eway">click here</Link> to extend..
        </span>
      ),
      placement,
      duration: 0,
      onClick: () => {
        notification.destroy();
      },
    });
  };
  const now = new Date();

  // useEffect(() => {

  const [isNotificationTriggered, setIsNotificationTriggered] = useState(false);

  const triggerNotification = () => {
    const now = new Date();
    const fourPm = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      16,
      0,
      0
    );
    const twelveAm = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    const isNotificationAlreadyTriggered = localStorage.getItem(
      "isNotificationTriggered"
    );
    if (userinfo.roles.includes("ROLE_HUBADMIN")) {
      if (
        (now >= fourPm || now <= twelveAm) &&
        !isNotificationTriggered &&
        !isNotificationAlreadyTriggered
      ) {
        openNotification("top");
        setIsNotificationTriggered(true);
        localStorage.setItem("isNotificationTriggered", true);
      }
    }
  };
  useEffect(() => {
    sessionOut();
  }, [userinfo.accessToken, isNotificationTriggered]);

  const sessionOut = () => {
    setInterval(() => {
      triggerNotification();
    }, 1000);
  };

  const showMessage = () => {
    const key = "customMessageKey";
    message.error({
      content: "Invalid tracking number",
      duration: 1,
      className: "track-err-msg",
      key,
    });
  };

  const routeToTracking = () => {
    let track = trackingNumber.replace(/\s+/g, "");
    if (track.length == 11 || track.length == 14) {
      let a = track;
      setTrackingNumber("");
      navigate("/shipment-tracker", { state: { awb: a, num: Math.random() } });
    } else {
      showMessage();
      // message.error("Invalid tracking number", 20);
    }
  };

  const handleInputChange = (value) => {
    if (!isNaN(value)) {
      setTrackingNumber(value);
    }
  };

  return (
    <div className="header">
      {contextHolder}

      <div className="header-content">
        <div className="d-flex space-between">
          <div className="space-between">
            <img
              src={hamIcon}
              className="mr-1 ham-mobile"
              onClick={handleOpenDrawer}
            />
            {/* {showLogo && ( */}
            <div className="banner">
              <img src={appLogo} className="web-logo" />
              <img src={mobileLogo} className="mobile-logo" />
            </div>
            {/* )} */}
          </div>

          <div className="align-center">
            <div className="align-center mr-1">
              <Input
                style={{
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                value={trackingNumber}
                placeholder="Enter Tracking ID"
                onChange={(e) => handleInputChange(e.target.value)}
                onPressEnter={routeToTracking}
              />
              <Button
                style={{
                  backgroundColor: "#2e77d0",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  height: "30px",
                  width: "70px",
                }}
                onClick={routeToTracking}
                className="save-btn"
              >
                Track
              </Button>
            </div>
            <div className="time pr-1 align-center mr-1">
              <img src={calender} />
              <span>{MyComponent()}</span>
              <img src={clock} />
              <span>{time}</span>
            </div>
            <div className="profile1 d-flex center">
              <img
                src={cart}
                alt="cart"
                // onClick={() => navigate("/cart-details")}
                // className="pointer"
                disabled={true}
              />
              {cartDetails === 0 ? (
                ""
              ) : (
                <div className="cart-count">
                  <span>{cartDetails}</span>
                </div>
              )}
            </div>
            <div className="profile1 d-flex center ml-1">
              <Dropdown
                menu={{
                  items,
                }}
                overlayClassName="nav-dropdown-overlay nav-dropdown-overlay-notification"
                //onOpenChange={(open) => initialize(open)}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <img src={bell} />
                  {notificationList?.length > 0 &&
                    notificationList.some((e) => e.viewed == false) && (
                      <div className="notification-div"></div>
                    )}
                </a>
              </Dropdown>
            </div>
            <Dropdown overlay={menu} overlayClassName="nav-dropdown-overlay">
              <div className="d-flex center avatar">
                <span style={{ padding: "0 0 0 4px" }}>
                  {userName?.toUpperCase() + " "}({getRole})
                </span>
                <div className="profile1 d-flex center ml-1">
                  <a>{userName?.substring(1, 0).toUpperCase() || "?"}</a>
                </div>
              </div>
            </Dropdown>
            {/* <div className="username letter">
              <Dropdown overlay={menu} overlayClassName="nav-dropdown-overlay">
                <>
                  <span style={{ fontSize: "15px" }}>
                    {userName?.substring(1, 0).toUpperCase() || "?" }
                  </span>
                  <img src={checkMark} />
                </>
              </Dropdown>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Button type="primary" onClick={openNotification}>
        Open the notification box
      </Button> */}
    </div>
  );
};
export default AppHeader;
