/* eslint-disable no-unused-vars */
//import React from "react";
// const BookingTabs = React.lazy(() =>
//   import("../views/viewBookingTable/viewBookingTabs")
// );
// const MainTabs = React.lazy(() =>
//   import("../views/CashBooking/CashBookingTabs")
// );

// const ScanedandManifestList = React.lazy(() =>
//   import("../views/viewBookingTable/ScanedandManifestList")
// );
// const ChildPacketScan = React.lazy(() =>
//   import("../views/viewBookingTable/ChildPacketScann")
// );
// const PrintManifest = React.lazy(() =>
//   import("../views/viewBookingTable/PrintManifest")
// );
// const AddPacketMapping = React.lazy(() =>
//   import("../views/Master/PacketingGuide/AddPacketMapping")
// );
// const ViewManifest = React.lazy(() => import("../views/ViewManifest"));
// const Truck = React.lazy(() => import("../views/viewBookingTable/Truck"));
// const AddPacket = React.lazy(() =>
//   import("../views/viewBookingTable/Addpacket")
// );
// const Calculator = React.lazy(() => import("../views/Calculater"));
// const BookedSuccessfull = React.lazy(() =>
//   import("../views/BookSuccessfull/index")
// );
// const Broadcast = React.lazy(() =>
//   import("../views/Broadcast")
// );
// const Master = React.lazy(() =>
//   import("../views/Master")
// );
// const CashbookingRateCard = React.lazy(() =>
//   import("../views/Master/RateCard/CashbookingRateCard")
// );
// const AllocateTemplate = React.lazy(() =>
//   import("../views/Master/RateCard/CashbookingRateCard/allocateTemplate")
// );
// const Pincode = React.lazy(() =>
//   import("../views/Master/PincodeMaster")
// );
// const CpOnbordingTabs = React.lazy(() =>
//   import("../views/Master/CpOnbordingTabs")
// );
// const RouteMaster = React.lazy(() =>
//   import("../views/Master/Route")
// );
// const FirstAndLastMileTAT = React.lazy(() =>
//   import("../views/Master/TAT/FirstAndLastMile")
// );
// const VehicleOnboard = React.lazy(() =>
//   import("../views/Master/VehicleOnboard")
// );
// const MiddleMileTAT = React.lazy(() =>
//   import("../views/Master/TAT/middleMile")
// );
// const MasterChild = React.lazy(() =>
//   import("../views/Master/RateCard/masterChild")
// );
// const PacketingGuide = React.lazy(() =>
//   import("../views/Master/PacketingGuide")
// );
// const PinCodeMapping = React.lazy(() =>
//   import("../views/Master/PinCodeMapping")
// );

//-------------------------MASTER START-----------------------------------//

import Master from "../views/Master";
import AddPacketMapping from "../views/Master/PacketingGuide/AddPacketMapping";
import CashbookingRateCard from "../views/Master/RateCard/CashbookingRateCard";
import PincodeMaster from "../views/Master/PincodeMaster";
import RouteMaster from "../views/Master/Route";
import HubRouteMaster from "../views/Master/vehicleRoute";

import FirstAndLastMileTAT from "../views/Master/TAT/FirstAndLastMile";
import VehicleOnboard from "../views/Master/VehicleOnboard";
import MiddleMileTAT from "../views/Master/TAT/middleMile";
import MasterChild from "../views/Master/RateCard/masterChild";
import PacketingGuide from "../views/Master/PacketingGuide";
import PinCodeMapping from "../views/Master/PinCodeMapping";
import TatConfiguration from "../views/Master/TAT/TatConfiguration";
import TatCalulator from "../views/Master/TAT/TatCalculator";
import VehicleMaster from "../views/Master/Vehicle";
import SortingGuide from "../views/Master/SortingGuide";
import HubOnboarding from "../views/Master/HubOnboard";
import AllocateTemplate from "../views/Master/RateCard/CashbookingRateCard/allocateTemplate";
// import AccountCreateTemplate from "../views/Master/RateCard/AccountBookingRateCard/AccountCreateTemplate";
// import ViewDetails from "../views/Master/CpOnbordingSteps/ViewDetails";
// import CpOnbordingTabs from "../views/Master/CpOnbordingTabs";

//-------------------------MASTER END-----------------------------------//

//-------------------------FIRST MILE START-----------------------------------//

import BookingTabs from "../views/viewBookingTable/viewBookingTabs";
import RetailBooking from "../views/FirstMile/RetailBooking";
import ScanedandManifestList from "../views/viewBookingTable/ScanedandManifestList";
import ChildPacketScan from "../views/viewBookingTable/ChildPacketScann";
import PrintManifest from "../views/viewBookingTable/PrintManifest";
import ViewManifest from "../views/viewBookingTable/viewBookingTabs/ViewManifest";
import Truck from "../views/viewBookingTable/Truck";
import AddPacket from "../views/viewBookingTable/Addpacket";
import NewBookedSuccessfull from "../views/FirstMile/RetailBooking/BookingSuccess";

import CPEWayBill from "../views/EwayBill/CPEwayBill";
import LastMileEWayBill from "../views/EwayBill/LastMileEwayBill";
import printEwayBill from "../views/EwayBill/PrintEwayBill/index";

//-------------------------FIRST MILE END-----------------------------------//

//----------------------------Middle Mile START---------------------------------------//
import AuditTabs from "../views/MiddleMileOperations/Audit";
import ConsolidatedList from "../views/MiddleMileOperations/InScan";
import PrintConsolidatedManifest from "../views/MiddleMileOperations/InScan/PrintConsolidatedManifest";
import CreateTrip from "../views/MiddleMileOperations/LCRGeneration/createTrip";
import LcrList from "../views/MiddleMileOperations/Outscan&Lcr/LcrList";
import PrintLcr from "../views/MiddleMileOperations/Outscan&Lcr/PrintLcr";
import ManifestList from "../views/MiddleMileOperations/ManifestList/index";
import UpdateTripList from "../views/MiddleMileOperations/Outscan&Lcr/UpdateTripList";
import AuditScannedTable from "../views/MiddleMileOperations/AuditScannedTable";
import ViewSortingMM from "../views/MiddleMileOperations/Sorting";
// import Packeting from "../views/MiddleMileOperations/HubPacketing";
import Bagging from "../views/MiddleMileOperations/Bagging/baggingTab";
import LcrTab from "../views/MiddleMileOperations/LCRGeneration/LcrTab";
import LCR from "../views/MiddleMileOperations/LCR";
import Tracker from "../views/MiddleMileOperations/Tracker";
import SortToCp from "../views/MiddleMileOperations/SortToCp";
import CalendarView from "../views/MiddleMileOperations/TripScheduler/CalendarView";
import ExcessInscan from "../views/MiddleMileOperations/ExcessInscan";
import EwayTracker from "../views/MiddleMileOperations/EwayTracker";
import LCRListScan from "../views/MiddleMileOperations/LCRInScanList";
import LCRScanList from "../views/MiddleMileOperations/LCRScanList";

import HubManifestEWayBill from "../views/EwayBill/HubManifestEwayBill";
import HubEWayBill from "../views/EwayBill/HubEwayBill";
import ExtendEWayBill from "../views/EwayBill/ExtendEwayBill";
import Audits from "../views/Audit";

import UpdateTripSchedule from "../views/MiddleMileOperations/TripScheduler/UpdateTripSchedule";
import CreateOrUpdateTrip from "../views/MiddleMileOperations/Outscan&Lcr/CreateOrUpdateTrip";
import ShipmentListReport from "../views/MiddleMileOperations/LCR/ShipmentReport/shipmentListReport";
import OutscanManifestList from "../views/MiddleMileOperations/OutscanManifest";
import ExceptionReport from "../views/MiddleMileOperations/ExceptionReports";

// import LCRBagInScan from "../views/MiddleMileOperations/BagScannig";
// import OpenBag from "../views/MiddleMileOperations/OpenBag";
//import Bagging from "../views/MiddleMileOperations/Bagging";
// import LcrPacketsInScan from "../views/MiddleMileOperations/LCRPackets";
// import InScan from "../views/MiddleMileOperations/InScan";
// import AllManifestInScan from "../views/MiddleMileOperations/InScan/manifestInscan";

//----------------------------Middle Mile END---------------------------------------//

//----------------------------LastMileOperations START-----------------------------------//

import DrsGeneration from "../views/LastMileOperations/DrsGeneration/index";
import InScanCP from "../views/LastMileOperations/InScanCP";
import CreateVirtualArea from "../views/LastMileOperations/VirtualArea";
import DrsDetails from "../views/LastMileOperations/DrsGeneration/DrsDetails";
import printDrs from "../views/LastMileOperations/DrsGeneration/printDrs";
import PrintGenerateManifest from "../views/PrintGenerateManifest";
import PendingDrs from "../views/LastMileOperations/PendingDrs";
import CashRTo from "../views/LastMileOperations/RTO/CashRto";
import CreditRTO from "../views/LastMileOperations/RTO/CreditRto";
// import CreateVirtualArea from "../views/LastMileOperations/CreateVirtualArea";

//----------------------------LastMileOperations END-----------------------------------//

//----------------------------Stationary START---------------------------------------//

import AddCategory from "../views/HoStationary/AddCategory/inedx";
import AddProduct from "../views/HoStationary/AddProduct";
import ProductCP from "../views/CPStationary/Product";
import ProductDetails from "../views/CPStationary/Product/productDetails";
import HoStationary from "../views/HoStationary";
import ProductTabs from "../views/HoStationary/productTabs";
import OrderRequest from "../views/HoStationary/OrderRequest";
import CartPage from "../views/CPStationary/Cart";
import OrderHistory from "../views/CPStationary/Order";
import OrderDetails from "../views/CPStationary/Order/orderDetails";
//----------------------------Stationary END---------------------------------------//

// import GenerateManifest from "../views/GenerateManifest";
import GenerateManifestTabs from "../views/GenerateManifestTabs";
import ExcesInscan from "../views/ExcesInscan";
import StateMaster from "../views/StateMaster/index";
import PrsGenerationTab from "../views/PrsGeneration/PrsGenerationTab";

import VehicleList from "../views/Report/Vehicle";
import TripListByVehicle from "../views/Report/Vehicle/tripList";
import VehicleCkinCkout from "../views/Report/VehicleCkinCkout";
// CP Onboarding New

import CpOnboardingNew from "../views/CpOnboardingNew/index";
import KycView from "../views/CpOnboardingNew/KycView";

import CpLeadGen from "../views/LeadGenerationCP";
import ClientLeadList from "../views/LeadGenerationClient";
import CPLeadList from "../views/CPLeadList";
import ClientLeadGeneration from "../views/LeadGenerationClient/LeadGenClientSteps";
import ClientQuotationList from "../views/LeadGenerationClient/CreateQuoteList";
import CPQuotationList from "../views/LeadGenerationCP/CPQuotationList";

import NewQuotation from "../views/LeadGenerationClient/NewQuote";
import CPNewQuotation from "../views/LeadGenerationCP/AllocateRateCard";

import ScheduleMeetingModal from "../views/ClientLeadList/ScheduleMeetingModal";
import SendEmailModal from "../views/ClientLeadList/SendEmailModal";
import CPApprovalLeadList from "../views/AllApprovals/CPLeadApproval";
import LeadDetails from "../views/AllApprovals/CPLeadApproval/LeadDetails";
import { BillingConfiguration } from "../views/BillingConfiguration";
// import BillingConfiguration from "../views/BillingConfiguration";
// import BillingConfiguration from "../views/BillingConfiguration/BillingConfiguration";

import LeadApprovalConfirmation from "../views/AllApprovals/LeadApprovalConfirmation";
import AllApprovals from "../views/AllApprovals";
import RequestBranch from "../views/RequestBranch/index";
import ViewApprovalDPBasicDetails from "../views/AllApprovals/ClientLeadApproval/ClientLeadDetails";
import ViewPincodeMap from "../views/AllApprovals/ClientLeadApproval/viewPincodeMap";
import ViewRateCard from "../views/AllApprovals/ClientLeadApproval/viewRateCard";

import { Stationary } from "../views/middleMileSubRoutes";

import Broadcast from "../views/Broadcast";

import StaffOnboarding from "../views/onboarding_/StaffOnboarding";
import constants from "../constants/constants";

import "antd/dist/antd.css";
import "../App.scss";
import RateCardList from "../views/RateCardList";
import CrediteBooking from "../views/FirstMile/CrediteBooking";
import Discount from "../views/FirstMile/RetailBooking/ApplyDiscount";
import DiscountedList from "../views/FirstMile/RetailBooking/DiscountedList";
import NewRetailUX from "../views/FirstMile/RetailBooking/NewRetailUX";
import ShipmentTracker from "../views/ShipmentTracker";
import CPWiseHubManifestEWayBill from "../views/EwayBill/CPWiseHubManifestEWayBill";

//import Guides from "../views/Guides";
// import LeadGenList from "../views/LeadGeneration";
// import OnborginTabs from "../views/Onbording";
// import ClientOnboarding from "../views/onboarding_/ClientOnboarding";
// import ClientTable from "../views/onboarding_/ClientOnboarding/viewClientTable";
// import ViewClientDetails from "../views/onboarding_/ClientOnboarding/ViewClientDetails";

const { ROLES } = constants;
const { CP_ADMIN, HO_ADMIN, HUB_ADMIN, ROLE_HOMARKETING } = ROLES;

export const routeList = [
  {
    component: BookingTabs,
    route: "/viewBooking",
    role: [CP_ADMIN],
  },
  {
    component: CpLeadGen,
    route: "/cp-lead-generation",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: CPLeadList,
    route: "/cp-lead-generation-list",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: ClientLeadList,
    route: "/client-lead-generation-list",
    role: [CP_ADMIN],
  },
  {
    component: ClientLeadList,
    route: "/dp-lead-generation-list",
    role: [ROLE_HOMARKETING],
  },
  {
    component: ClientLeadGeneration,
    route: "/client-lead-generation/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: ClientLeadGeneration,
    route: "/client-lead-generation",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: ViewApprovalDPBasicDetails,
    route: "/approve-dp/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: ViewPincodeMap,
    route: "/view-pincode-mapping/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: ViewRateCard,
    route: "/view-rate-card/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: ClientQuotationList,
    route: "/quotation-list/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
    ROLE_HOMARKETING,
  },
  {
    component: CPQuotationList,
    route: "/cp-quotation-list/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
    ROLE_HOMARKETING,
  },
  {
    component: NewQuotation,
    route: "/client/:clientId/new-quotation/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: NewQuotation,
    route: "/new-quotation/:clientId",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: CPNewQuotation,
    route: "/cp-quotation-new/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: CPNewQuotation,
    route: "/cp-quotation-edit/:id",
    role: [HUB_ADMIN, CP_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: AllApprovals,
    route: "/all-approvals",
    role: [HUB_ADMIN, HO_ADMIN],
  },
  {
    component: PrsGenerationTab,
    route: "/prs-generation",
    role: [HUB_ADMIN],
  },
  {
    component: UpdateTripSchedule,
    route: "/create-schedule",
    role: [CP_ADMIN, HO_ADMIN, HUB_ADMIN],
  },
  {
    component: CPEWayBill,
    route: "/cp-eway-bill",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: HubManifestEWayBill,
    route: "/hub-manifest-eway/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: LastMileEWayBill,
    route: "/last-mile-eway-bill/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: HubEWayBill,
    route: "/hub-eway-bill/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: ExtendEWayBill,
    route: "/extend-eway",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },

  {
    component: NewRetailUX,
    route: "/retail-booking",
    role: [CP_ADMIN],
  },

  // {
  //   component: RetailBooking,
  //   route: "/retail-booking",
  //   role: [CP_ADMIN],
  // },
  {
    component: ScanedandManifestList,
    route: "/scan",
    role: [CP_ADMIN],
  },
  {
    component: ChildPacketScan,
    route: "/child-scan",
    role: [CP_ADMIN],
  },
  {
    component: PrintManifest,
    route: "/print",
    role: [CP_ADMIN, HO_ADMIN, HUB_ADMIN],
  },
  {
    component: Master,
    route: "/last-mile-operations",
    role: [CP_ADMIN],
  },
  {
    component: InScanCP,
    route: "/in-scan-cp",
    role: [CP_ADMIN],
  },
  {
    component: CreateVirtualArea,
    route: "/create-virtual-area",
    role: [CP_ADMIN],
  },
  {
    component: AddPacketMapping,
    route: "/addpacket-mapping",
    role: [HO_ADMIN],
  },
  {
    component: ViewManifest,
    route: "/view-manifest",
    role: [CP_ADMIN],
  },
  {
    component: Truck,
    route: "/truck",
    role: [CP_ADMIN],
  },
  {
    component: AddPacket,
    route: "/viewBooking-packet",
    role: [CP_ADMIN],
  },

  {
    component: NewBookedSuccessfull,
    route: "/retail-booking-success",
    role: [CP_ADMIN],
  },
  {
    component: Master,
    route: "/masters",
    role: [HO_ADMIN, HUB_ADMIN],
  },
  {
    component: CashbookingRateCard,
    route: "/cash-booking-ratecard",
    role: [HO_ADMIN],
  },
  {
    component: AllocateTemplate,
    route: "/allocate-template",
    role: [HO_ADMIN],
  },
  {
    component: RouteMaster,
    route: "/route-configuration",
    role: [HO_ADMIN, HUB_ADMIN],
  },
  {
    component: HubRouteMaster,
    route: "/hub-route-configuration",
    role: [HUB_ADMIN],
  },
  {
    component: FirstAndLastMileTAT,
    route: "/first-last-mile-tat",
    role: [HO_ADMIN],
  },
  {
    component: PincodeMaster,
    route: "/pincode-master",
    role: [HO_ADMIN],
  },
  {
    component: CpLeadGen,
    route: "/cp-onboarding",
    role: [HO_ADMIN, CP_ADMIN],
  },
  {
    component: VehicleOnboard,
    route: "/vehicle-onboard",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    component: MiddleMileTAT,
    route: "/middle-mile-tat",
    role: [HO_ADMIN],
  },
  {
    component: MasterChild,
    route: "/master-card-child",
    role: [HO_ADMIN],
  },
  {
    component: PacketingGuide,
    route: "/packeting-guide",
    role: [HO_ADMIN],
  },
  {
    component: PinCodeMapping,
    route: "/pin-code-mapping",
    role: [HO_ADMIN],
  },

  {
    component: Broadcast,
    route: "/message-and-alerts",
    role: [HO_ADMIN],
  },
  {
    component: Master,
    route: "/onboarding-settings",
    role: [HO_ADMIN, CP_ADMIN, HUB_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: Master,
    route: "/middle-mile",
    role: [HUB_ADMIN],
  },
  {
    component: Master,
    route: "/hub-in-scan",
    role: [HUB_ADMIN],
  },
  {
    component: printEwayBill,
    route: "/print-eway-bill",
    role: [HUB_ADMIN, CP_ADMIN],
  },
  {
    component: Master,
    route: "/configuration-guide",
    role: [HUB_ADMIN],
  },

  {
    component: CreateOrUpdateTrip,
    route: "/create-or-update-trip",
    role: [HUB_ADMIN],
  },
  {
    component: ManifestList,
    route: "/manifest-list",
    role: [HUB_ADMIN, CP_ADMIN],
  },

  {
    component: UpdateTripList,
    route: "/update-trip-list",
    role: [HUB_ADMIN],
  },
  {
    component: Audits,
    route: "/audits",
    role: [HUB_ADMIN],
  },
  {
    component: HubOnboarding,
    route: "/hub-onboarding",
    role: [HO_ADMIN],
  },

  {
    component: SortingGuide,
    route: "/sorting-guide",
    role: [HO_ADMIN, HUB_ADMIN],
  },

  {
    component: StaffOnboarding,
    route: "/staff-onboarding",
    role: [HO_ADMIN, CP_ADMIN, HUB_ADMIN],
  },
  {
    component: AuditTabs,
    route: "/manifest-audit",
    role: [HO_ADMIN, HUB_ADMIN],
  },
  {
    component: DrsGeneration,
    route: "/generate-drs",
    role: [CP_ADMIN],
  },
  {
    component: printDrs,
    route: "/print-drs",
    role: [CP_ADMIN],
  },
  {
    component: DrsDetails,
    route: "/drs-details",
    role: [CP_ADMIN],
  },
  {
    component: PendingDrs,
    route: "/pending-drs",
    role: [CP_ADMIN],
  },

  {
    component: AuditScannedTable,
    // route: "/in-scan",
    route: "/audit-scanned",
    role: [HUB_ADMIN],
  },
  // {
  //   component: Packeting,
  //   route: "/packeting",
  //   role: [HUB_ADMIN],
  // },
  {
    component: ViewSortingMM,
    route: "/sort-shipment",
    role: [HUB_ADMIN],
  },
  {
    component: Tracker,
    route: "/manifest-tracker/:id",
    role: [CP_ADMIN, HO_ADMIN, HUB_ADMIN],
  },
  // deepak's rout
  { component: CreateTrip, route: "/create-trip", role: [HUB_ADMIN] },
  { component: LcrTab, route: "/lcr-tab", role: [HUB_ADMIN] },
  { component: LCR, route: "/lcr-generation", role: [HUB_ADMIN] },
  { component: Bagging, route: "/shipment-bagging", role: [HUB_ADMIN] },
  {
    component: LcrList,
    route: "/lcr-list",
    role: [HUB_ADMIN],
  },
  {
    component: PrintLcr,
    route: "/print-lcr",
    role: [HUB_ADMIN],
  },
  {
    component: ConsolidatedList,
    route: "/consolidated-manifest",
    role: [HUB_ADMIN],
  },
  {
    component: PrintLcr,
    route: "/print-lcr",
    role: [HUB_ADMIN],
  },
  {
    component: PrintConsolidatedManifest,
    route: "/print-consolidated-manifest/:id",
    role: [HUB_ADMIN],
  },

  {
    component: LCRListScan,
    route: "/lcr-list-in-scan",
    role: [HUB_ADMIN],
  },
  {
    component: LCRScanList,
    route: "/lcr-scan-list",
    role: [HUB_ADMIN],
  },

  {
    component: SortToCp,
    route: "/sort-to-cp",
    role: [HUB_ADMIN],
  },
  {
    component: OutscanManifestList,
    route: "/generateManifest",
    role: [HUB_ADMIN],
  },
  {
    component: PrintGenerateManifest,
    route: "/print-generate-manifest/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: ExcesInscan,
    route: "/excess-inscan",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: VehicleList,
    route: "/vehicle-list",
    role: [HUB_ADMIN, HO_ADMIN],
  },
  {
    component: VehicleCkinCkout,
    route: "/vehicle-checkin-checkout",
    role: [HUB_ADMIN],
  },
  {
    component: TripListByVehicle,
    route: "/vehicle-trip-list",
    role: [HUB_ADMIN, HO_ADMIN],
  },
  {
    component: Master,
    route: "/reports",
    role: [HUB_ADMIN],
  },
  {
    component: CalendarView,
    route: "/trip-scheduler",
    role: [HUB_ADMIN],
  },
  {
    component: TatConfiguration,
    route: "/tat-config",
    role: [HO_ADMIN],
  },
  {
    component: TatCalulator,
    route: "/tat-calculator",
    role: [HO_ADMIN],
  },
  {
    component: VehicleMaster,
    route: "/vehicle-master",
    role: [HUB_ADMIN, HO_ADMIN],
  },
  // {
  //   component: ExcessInscan,
  //   route: "/excess-misrouted",
  //   role: [HUB_ADMIN, HO_ADMIN],
  // },

  // cashRTo
  {
    component: CashRTo,
    route: "/cash-rto/:id",
    role: [HUB_ADMIN, HO_ADMIN, CP_ADMIN],
  },
  {
    component: CreditRTO,
    route: "/credit-rto/:id",
    role: [HUB_ADMIN, HO_ADMIN, CP_ADMIN],
  },
  {
    component: EwayTracker,
    route: "/eway-tracker/:id",
    // route: "/manifest-tracker/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: LeadDetails,
    route: "/lead-details/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: CPApprovalLeadList,
    route: "/cp-approval-lead-list",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  {
    component: CpOnboardingNew,
    route: "cp-details",
    // route: "/manifest-tracker/:id",

    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: ScheduleMeetingModal,
    route: "schedule-meeting",
    // route: "/manifest-tracker/:id",

    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: SendEmailModal,
    route: "send-mail",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: StateMaster,
    route: "/add-state-master",
    role: [HO_ADMIN],
  },
  {
    component: LeadApprovalConfirmation,
    route: "/lead-approval-confirmation/:id",

    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: BillingConfiguration,
    route: "/billing-config",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: RateCardList,
    route: "/rate-card-list",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },
  {
    component: KycView,
    route: "kyc-view",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: RequestBranch,
    route: "request-for-branch",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },
  {
    component: CrediteBooking,
    route: "credit-booking",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN, ROLE_HOMARKETING],
  },

  //--//

  //--Middle Mile--//
  // {
  //   component: InScan,
  //   route: "/in-scan/:id",
  //   // route: "/manifest-in-scan",
  //   role: [HUB_ADMIN],
  // },
  // {
  //   component: AllManifestInScan,
  //   route: "/manifest-in-scan",
  //   // route: "/manifest-in-scan",
  //   role: [HUB_ADMIN],
  // },

  // {
  //   component: LCRBagInScan,
  //   route: "/lcr-bag-scan/:id",
  //   role: [HUB_ADMIN],
  // },

  // {
  //   component: OpenBag,
  //   route: "/lcr-bag-open/:id",
  //   role: [HUB_ADMIN],
  // },

  // {
  //   component: ClientOnboarding,
  //   route: "/client-onboarding",
  //   role: [CP_ADMIN],
  // },
  // {
  //   component: ClientTable,
  //   route: "/client-list",
  //   role: [CP_ADMIN, HO_ADMIN],
  // },
  // {
  //   component: ViewClientDetails,
  //   route: "/view-client/:id",
  //   role: [CP_ADMIN, HO_ADMIN],
  // },

  // {
  //   component: OnborginTabs,
  //   route: "/cp-onboarding",
  //   role: ["ROLE_HOADMIN"],
  // },
  // {
  //   component: Guides,
  //   route: "/guides",
  //   role: [HUB_ADMIN],
  // },
  // {
  //   component: ViewDetails,
  //   route: "/view-details",
  //   role: [HO_ADMIN, CP_ADMIN],
  // },
  // {
  //   component: Stationary,
  //   route: "/stationery",
  //   role: [HO_ADMIN, CP_ADMIN, HUB_ADMIN],
  // },

  //stationary
  {
    component: HoStationary,
    route: "/stationery",
    role: [HO_ADMIN, HUB_ADMIN, CP_ADMIN],
  },
  {
    component: AddCategory,
    route: "/add-category",
    role: [HO_ADMIN],
  },
  {
    component: AddProduct,
    route: "/add-product",
    role: [HO_ADMIN],
  },
  {
    component: ProductTabs,
    route: "/product",
    role: [HO_ADMIN],
  },

  {
    component: ProductCP,
    route: "/product-list",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    component: ProductDetails,
    route: "/product-details/:id",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    component: CartPage,
    route: "/cart-details",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    component: OrderRequest,
    route: "/order-request",
    role: [HO_ADMIN],
  },
  {
    component: OrderHistory,
    route: "/order-history",
    role: [CP_ADMIN, HUB_ADMIN],
  },
  {
    component: OrderDetails,
    route: "/order-details/:id",
    role: [CP_ADMIN, HUB_ADMIN, HO_ADMIN],
  },

  {
    component: Discount,
    route: "/retail-discount",
    role: [CP_ADMIN],
  },
  {
    component: DiscountedList,
    route: "/retail-discounted-list",
    role: [CP_ADMIN],
  },
  {
    component: NewRetailUX,
    route: "/new-retail-ux",
    role: [CP_ADMIN],
  },
  {
    component: ShipmentListReport,
    route: "/shipment-report",
    role: [HUB_ADMIN],
  },

  {
    component: CPWiseHubManifestEWayBill,
    route: "/cp-wise-Eway/:id",
    role: [HUB_ADMIN],
  },

  {
    component: PrintManifest,
    route: "/cp-wise-manifest-print/:id",
    role: [HUB_ADMIN],
  },

  {
    component: ExceptionReport,
    route: "/exception-report",
    role: [HUB_ADMIN],
  },
  {
    component: ShipmentTracker,
    route: "/shipment-tracker",
    role: [HO_ADMIN, HUB_ADMIN, CP_ADMIN],
  },
];
