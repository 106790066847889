/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Input,
  Button,
  Table,
  Row,
  // Col,
  message,
  Tooltip,
  Spin,
  Select,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router";
import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  serialNum,
  dateFormat_dmy,
  commonSorterForTable,
  normalizeString,
} from "../../../utils";
const { RangePicker } = DatePicker;
import { camelCase } from "../../../utils";
import search_icon from "../../../assests/images/search-icon.svg";
import { ReactComponent as Copy_Icon } from "../../../assests/images/SVG/copy-icon.svg";
// import urls from "../../../api/urls";
import API from "../../../api";
import urls from "../../../api/urls";
//import { APIs } from "../../../utils/worker";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import ScannedShipment from "../../../components/ScannedShipment";
import nodeURL from "../../../api/nodeUrls";
const { NEW_INSCAN } = urls;
const { MANIFEST_DATE_FILTER_NODE } = nodeURL;
// const premiseId = getPremiseId();

const InScan = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showtoolTip, setShowtoolTip] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [listCount, setListCount] = useState(0);
  const [dateRangeData, setDateRangeData] = useState([]);
  const [lastDate, setLastDate] = useState({});
  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const [filterType, setFilterType] = useState("both");

  const [sortedInfo, setSortedInfo] = useState({});
  const handleScan = async (id) => {
    setLoading(true);
    const resultNumber = id?.replace(/[a-zA-Z]/g, "");

    let barcodes = +resultNumber;
    let response = await API.Api(NEW_INSCAN).postIdHeader({
      barcodes,
    });

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data;

      if (data?.split(" ")?.includes("Misrouted")) {
        message.warning(data);
      } else {
        message.success(data || resultNumber + " scanned successfully");
      }

      const updatedTableData = tableData?.map((obj) => ({
        ...obj,
        bookingStatus:
          obj?.awbNumber == +resultNumber ? "Inscanned" : obj?.bookingStatus,
      }));
      setTableData(updatedTableData);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message ||
          resultNumber + " " + "Network error"
      );
    }
  };

  // let filterByManifest = () => {
  //   const unique = [
  //     ...new Map(tableData?.map((item) => [item["manifestId"], item])).values(),
  //   ];

  //   let data = unique?.map((val) => {
  //     return { text: val?.manifestId, value: val?.manifestId };
  //   });

  //   return data;
  // };

  function copyText(text) {
    setShowtoolTip(!showtoolTip);
    navigator.clipboard.writeText(text);
  }

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  // function convertToIST(isoDateString) {
  //   // Convert to Date object
  //   const date = new Date(isoDateString);

  //   // Options for formatting the date to IST
  //   const options = {
  //     timeZone: "Asia/Kolkata",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false, // Use 24-hour time format
  //   };

  //   // Format the date to IST
  //   const formatter = new Intl.DateTimeFormat("en-GB", options);
  //   const formattedDate = formatter.format(date);

  //   return formattedDate;
  // }

  const convertDate = (isoDate) => {
    return moment.utc(isoDate).format("DD/MM/YYYY HH:mm");
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      // width: 10,
      render: (a, b, idx) => serialNum(pageSize, currentPage, idx),
    },
    {
      title: "Awb No/Packet Id",
      dataIndex: "awbNumber",
      width: 200,
      key: "awbNumber",
      sorter: (a, b) => commonSorterForTable(a, b, "bookingStatus"),
      sortOrder:
        sortedInfo?.columnKey === "awbNumber" ? sortedInfo?.order : null,
      render: (text, val) => {
        return (
          <div className="d-flex">
            <div style={{ width: "170px" }}>
              <span style={{ marginRight: "15px" }}>{text}</span>
            </div>

            <Tooltip title={showtoolTip ? "Copied" : "Copy"}>
              <span
                style={{ marginRight: "10px" }}
                className="pointer"
                onClick={() => copyText(text)}>
                <Copy_Icon />
              </span>
            </Tooltip>

            {scannedStatusCheck(val) && <Scan_check />}
          </div>
        );
      },
    },

    // {
    //   title: "Manifest ID",
    //   dataIndex: "manifestId",
    //   filters: filterByManifest(),
    //   width: 150,
    //   onFilter: (value, record) => record?.manifestId === value,
    //   key: "manifestId",
    // },

    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      sorter: (a, b) => commonSorterForTable(a, b, "createDate"),
      sortOrder:
        sortedInfo?.columnKey === "createDate" ? sortedInfo?.order : null,
      ellipsis: true,
      // render: (text) => <span>{text ? text.split("T")[0] : "----"}</span>,
      render: (text) => <span>{text ? convertDate(text) : "----"}</span>,
    },
    {
      title: "CP Name",
      dataIndex: "premiseName",
      key: "premiseName",
      sortOrder:
        sortedInfo.columnKey === "premiseName" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "premiseName"),
      ellipsis: true,
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Booking Type",
      dataIndex: "type",
      key: "type",
      sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "type"),
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Service Type",
      dataIndex: "service",
      key: "service",
      sorter: (a, b) => commonSorterForTable(a, b, "service"),
      sortOrder: sortedInfo.columnKey === "service" ? sortedInfo.order : null,
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      sorter: (a, b) => commonSorterForTable(a, b, "travelBy"),
      sortOrder: sortedInfo.columnKey === "travelBy" ? sortedInfo.order : null,
      render: (text) => <div>{camelCase(text)}</div>,
    },

    {
      title: "Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      sorter: (a, b) => commonSorterForTable(a, b, "bookingStatus"),
      sortOrder:
        sortedInfo.columnKey === "bookingStatus" ? sortedInfo.order : null,
      render: (text) => <div>{normalizeString(text)}</div>,
    },
  ];

  function naviGateBack() {
    navigate("/middle-mile");
  }

  function onPageChange(currentPage, pageSize) {
    // console.log(currentPage, "currentPage");
    // console.log(pageSize, "pageSize");

    if (dateRangeStatus) {
      dateSearchFun(currentPage, pageSize);
    }
    setCurrentPage(currentPage);
    setPageSize(pageSize);
  }

  // const onChange = (pagination, filters, sorter, extra) => {
  //   console.log(pagination, " on page hello");

  //   setCurrentPage(pagination?.current);
  //   setPageSize(pagination?.pageSize);
  //   let totalScann = extra.currentDataSource?.filter((val) => {
  //     return val?.bookingStatus !== "MANIFESTED";
  //   });

  //   return setTotalScanned({
  //     tableTotal: extra.currentDataSource.length,
  //     scannedCount: totalScann?.length,
  //   });
  // };

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleScan(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tableData]);

  const onSearch = (e) => {
    if (e.target.value.length > 10 && e.target.value.length < 15) {
      setFilterType("both");
      dateSearchFun(undefined, undefined, "", e.target.value);
    } else {
      dateSearchFun(undefined, undefined, "", "");
    }
  };

  function handlefilterChange(params) {
    setFilterType(params.toLowerCase());
    dateSearchFun(undefined, undefined, params.toLowerCase(), "");
  }
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "-" + mm + "-" + yyyy;
    const formattedYes =
      dd == "01"
        ? dd + "-" + mm + "-" + yyyy
        : (dd <= 10 ? "0" + (+dd - 1) : +dd - 1) + "-" + mm + "-" + yyyy;
    // console.log(formattedYes);
    let rangeData = [formattedYes, formattedToday];
    dateRangeHandle("", rangeData);
    setLastDate({ fromDate: rangeData[0] || "", toDate: rangeData[1] || "" });
    dateSearchFun(undefined, undefined, "", "", rangeData);
  }, []);

  const dateRangeHandle = (e, a) => {
    setLastDate({ fromDate: a[0] || "", toDate: a[1] || "" });
    setDateRangeData(a);
    if (a[0]?.length === 0) {
      // getTableData();
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  const dateConversion = (inputDate) => {
    const parsedDate = moment(inputDate, "DD-MM-YYYY");

    // Format the date to MM-DD-YYYY
    const formattedDate = parsedDate.format("MM-DD-YYYY");
    return formattedDate;
  };
  const dateSearchFun = async (
    currentPageNo,
    pageSiz,
    fil,
    searchInput,
    rangeData
  ) => {
    setLoading(true);
    let dateData = rangeData ? rangeData : dateRangeData;
    let queryParams = searchInput
      ? `&search=${searchInput}&filter=both`
      : `&filter=${fil ? fil : filterType}`;

    let response = await API.Api(
      `${MANIFEST_DATE_FILTER_NODE}?pageNum=${
        currentPageNo - 1 || 0
      }&pageSize=${pageSiz || 100}&fromDate=${dateConversion(
        dateData[0]
      )}&toDate=${dateConversion(dateData[1])}` + queryParams
    ).NodeGetWithPremise();
    if (response?.status) {
      let list = response?.response?.data?.consolidatedDto;
      let data = response?.response?.data;

      setTableData(
        list?.sort(function (a, b) {
          return a?.bookingStatus !== "MANIFESTED"
            ? 1
            : b?.bookingStatus != "MANIFESTED"
            ? -1
            : 0;
        })
      );

      // setDataSource(list);
      setListCount(data?.totalCount);

      setLoading(false);
    } else {
      setTableData([]);
      setListCount(0);

      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const disabledDate = (current) => {
    if (!current) return false; // If no date, do not disable

    // Get today's date
    const today = moment();
    // Calculate the date range for the last 15 days
    const startDate = today?.clone()?.subtract(15, "days")?.startOf("day");
    const endDate = today?.clone()?.endOf("day");

    // Disable dates that are outside the range
    return current < startDate || current > endDate;
  };

  const scannedStatusCheck = (status) =>
    status?.bookingStatus !== "MANIFESTED" &&
    status?.bookingStatus !== "OUT_FOR_DELIVERY";

  let scannedCount = tableData?.reduce(
    (acc, item) => scannedStatusCheck(item) && (acc += 1),
    0
  );

  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <div className="consolidated-manifest-cntr">
          <div className="new-header ml-1">
            <Row gutter={12} justify={"space-between"}>
              <div>
                <Input
                  placeholder="Enter AWB number to scan"
                  bordered
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    minWidth: "200px",
                  }}
                  maxLength={14}
                  suffix={false}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onPressEnter={(e) => {
                    if (
                      e.target.value.length == 11 ||
                      e.target.value.length == 14
                    ) {
                      handleScan(e.target.value);
                    } else {
                      message.destroy();
                      message.error("Barcode number must be  11 or 14 digits");
                      // message.config({
                      //   top: 100,
                      //   duration: 2,
                      //   maxCount: 3,
                      //   rtl: true,
                      //   prefixCls: "my-message",
                      // });
                    }
                  }}
                />
              </div>
              <div className="center">
                <div className="center">
                  <div className="flex-end">
                    <RangePicker
                      disabledDate={disabledDate}
                      onChange={dateRangeHandle}
                      value={[
                        Object.keys(lastDate)?.length > 0 &&
                        lastDate?.fromDate !== ""
                          ? moment(
                              lastDate?.fromDate?.replaceAll("-", "/"),
                              dateFormat_dmy
                            )
                          : "",
                        Object.keys(lastDate)?.length > 0 &&
                        lastDate?.toDate !== ""
                          ? moment(
                              lastDate?.toDate?.replaceAll("-", "/"),
                              dateFormat_dmy
                            )
                          : "",
                      ]}
                      format={dateFormat_dmy}
                      style={{
                        borderRadius: "5px 0 0 5px",
                        height: "40px",
                        width: "230px",
                      }}
                    />
                    <Button
                      className="center mr-1"
                      disabled={!dateRangeStatus}
                      style={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "0 5px 5px 0",
                      }}
                      onClick={dateSearchFun}>
                      <img src={search_icon} />
                    </Button>
                  </div>
                </div>
                <div className="center">
                  <Input
                    className="mr-1"
                    placeholder="Enter AWB number to search"
                    // disabled={!dateRangeStatus || tableData?.length == 0}
                    onChange={onSearch}
                    bordered
                    style={{
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    suffix={<img src={search_icon} />}
                  />
                </div>
                <div className="center">
                  <Select
                    defaultValue="both"
                    className="filter-select mr-1"
                    style={{
                      width: 100,
                    }}
                    disabled={!dateRangeStatus || tableData?.length == 0}
                    value={filterType}
                    onChange={handlefilterChange}
                    options={[
                      {
                        value: "scanned",
                        label: "Scanned",
                      },
                      {
                        value: "un-scanned",
                        label: "Unscanned",
                      },
                      {
                        value: "both",
                        label: "Both",
                      },
                    ]}
                  />
                </div>
                <div className="center">
                  <ScannedShipment
                    className="mr-1"
                    scannedData={scannedCount}
                    totalData={tableData?.length}
                    slug={"/consolidated-manifest"}
                    filterType={filterType}
                  />
                </div>
              </div>
            </Row>
          </div>
          <Table
            columns={columns}
            scroll={{
              x: 1500,
              // y: 500,
            }}
            dataSource={
              tableData
                ? [
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    ...tableData?.sort(function (a, b) {
                      return a?.bookingStatus === "Inscanned"
                        ? 1
                        : b?.bookingStatus === "Inscanned"
                        ? -1
                        : 0;
                    }),
                  ]
                : []
            }
            // loading={loading}
            onChange={handleChange}
            className="table-head-sticky"
            pagination={{
              pageSizeOptions: ["50", "100", "500", "1000"],
              pageSize: pageSize,
              total: listCount,
              current: currentPage,
              itemRender: itemRender,
              onChange: onPageChange,
            }}
          />
        </div>
        <Row className="flex-end mt-2">
          <Button
            className="save-btn"
            style={{ textAlign: "end" }}
            onClick={naviGateBack}
            // disabled={checkInScanned}
          >
            DONE
          </Button>
        </Row>
      </Spin>
    </>
  );
};

export default InScan;
