import React from "react";
import { Col, Form, Input, Row } from "antd";
const FovForm = ({
  fovData,
  insuranceData,
  handlingChargesData,
  premiumServiceChargesData,
  fuelServiceChargesData,
  codChargesData,
  handleFovFormChange,
  disabled,
  leadApproval = false,
}) => {
  // const validateDecimal = (e) =>{
  //   e.target.value = e.target.value
  //     .replace(/[^\d.]/g, "")
  //     .replace(new RegExp("(^[\\d]{" + 3 + "})[\\d]", "g"), "$1")
  //     .replace(/(\..*)\./g, "$1")
  //     .replace(new RegExp("(\\.[\\d]{" + 3 + "}).", "g"), "$1");
  // };

  // const preventDecimal = (e) =>{
  //   if (/[^0-9.]/.test(e.key) || ( (e.target.value.charAt(1) === ".") ? e.target.value.length > 3 : (e.target.value.charAt(2) === ".") ? e.target.value.length > 4 : e.target.value.length > 5)) {
  //     e.preventDefault();
  //   }else if(( (e.target.value.length === 3 && !(e.target.value.includes("."))) && e.key !== ".")){
  //     e.preventDefault();
  //   }
  // };

  return (
    <Row gutter={40}>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
        // style={{ marginTop: "1.3rem" }}
      >
        <Form.Item
          name="fov"
          label="FOV Charges"
          initialValue={fovData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter fov changes",
            },

            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="Value INR"
            value={fovData}
            disabled={disabled}
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              handleFovFormChange(e.target.value, "fov");
            }}
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
        //style={{ marginTop: "1.3rem" }}
      >
        <Form.Item
          name="insurance"
          label="Insurance Charges"
          initialValue={insuranceData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter insurance changes",
            },
            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="Value INR"
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleFovFormChange(e.target.value, "insurance")}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="handlingCharges"
          label="Handling Charges"
          initialValue={handlingChargesData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter handling changes",
            },
            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="Value INR"
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={handlingChargesData}
            onChange={(e) =>
              handleFovFormChange(e.target.value, "handlingCharges")
            }
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="premiumServiceCharges"
          label="Premium Service Charges"
          initialValue={premiumServiceChargesData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter premimum changes",
            },
            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="Premium Service Charges"
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={premiumServiceChargesData}
            onChange={(e) =>
              handleFovFormChange(e.target.value, "premiumServiceCharges")
            }
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="fuelServiceCharges"
          label="Fuel Service Charges"
          initialValue={fuelServiceChargesData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter fule changes",
            },
            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="Fuel Service Charges"
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={fuelServiceChargesData}
            onChange={(e) =>
              handleFovFormChange(e.target.value, "fuelServiceCharges")
            }
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col
        className="form-inputs"
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        offset={1}
      >
        <Form.Item
          name="codCharges"
          label="COD Charges"
          initialValue={codChargesData}
          rules={[
            leadApproval && {
              required: true,
              message: "Please enter cod changes",
            },
            {
              pattern: /^[0-9].*/g,
              message: "Initial value should be numeric.",
            },
            {
              pattern: /^\d{1,3}(\.\d{0,2})?$/,
              message: "Format should be 000.00",
            },
          ]}
        >
          <Input
            placeholder="COD Charges"
            // onKeyUp={(e)=>{
            //   validateDecimal(e);
            // }}
            onKeyPress={(e) => {
              if (/[^0-9.]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={codChargesData}
            onChange={(e) => handleFovFormChange(e.target.value, "codCharges")}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default FovForm;
