/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Tooltip } from "antd";
import React from "react";
import bookingDownload from "../../../assests/images/bookingDownload.svg";
import editIcon from "../../../assests/images/edit-logo.svg";
import { useNavigate } from "react-router-dom";
import {
  capitaliseWithHyphen,
} from "../../../utils";
import "./cashBookingTable.scss";
export const column = (
  MyComponent,
  statusFun,
  handleEdit,
  handleEditView,
  handleUpload,
  handleEyeButton
) => {
  
const navigate = useNavigate();
return([
  {
    title: "Document Number",
    dataIndex: "awbNumber",
    key: "awbNumber",
    className: "awbNumber",
    defaultSortOrder: "descend",
    render: (text, b) => <a onClick={() => handleEyeButton(b)}>{text} </a>,
  },
  {
    title: "Date",
    dataIndex: "bookingTime",
    key: "bookingTime",
    render: (text, b) => MyComponent(text),
  },

  {
    title: "Booking Type",
    dataIndex: "bookingType",
    key: "bookingType",
    render: (text) => text?.toUpperCase() === "ACCOUNT" ? "Credit" : "Retail"
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    render: (text) => capitaliseWithHyphen(text),
  },
  {
    title: "Mode",
    dataIndex: "travelBy",
    key: "travelBy",
    width: 150,
    render: (text, val) => capitaliseWithHyphen(text),
  },

  {
    title: "Status",
    dataIndex: "bookingStatus",
    key: "bookingStatus",
    width: 180,
    render: (text) => statusFun(text),
  },

  {
    title: "Action",
    align: "center",

    render: (a, b) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          columnGap: "5px",
          cursor: "pointer",
        }}
      >
        <Tooltip title="Edit">
          <p onClick={() => b?.bookingStatus?.toUpperCase() === "INITIATED" && navigate("/retail-booking", {state: b.awbNumber})} className="vbooking-icons" style={{cursor: b?.bookingStatus?.toUpperCase() === "INITIATED" ? "pointer": "not-allowed"}}>
            <img src={editIcon} style={{ width: "33px" }} />
          </p>
        </Tooltip>
        <Tooltip title="Upload">
          <p onClick={() => handleUpload(a)} className="vbooking-icons">
            <img src={bookingDownload} style={{ width: "33px" }} />
          </p>
        </Tooltip>
        {/* <Tooltip title="Download">
          <p className="vbooking-icons">
            <img
              src={uploadIcon}
              style={{ width: "33px", cursor: "not-allowed" }}
            />
          </p>
        </Tooltip>
        <Tooltip title="Summary">
          <p onClick={() => handleEyeButton(b)} className="vbooking-icons">
            <img src={eyeIcon} style={{ width: "33px" }} />
          </p>
        </Tooltip>
        <Tooltip title="Print">
          <p className="vbooking-icons">
            <img
              src={printerIcon}
              style={{ width: "30.5px", cursor: "not-allowed" }}
            />
          </p>
        </Tooltip> */}
      </div>
    ),
  },
]);
};
