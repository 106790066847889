/* eslint-disable no-unused-vars */
import { Divider, Steps, Row, Col, message, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddAgreement from "./AddAgreement";
import AddRateCard from "./AddRateCard";
import BusinessDetails from "./BusinessDetails";
import OrganisationDetails from "./OrganizationDetails";
import PincodeMapping from "./PincodeMapping";
import API from "../../../../api";
import urls from "../../../../api/urls";
import "./style.scss";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import { useParams } from "react-router";

const { UPDATE_LEAD_CLIENT_DETAILS, GET_CLIENT_LEAD_ID } = urls;

export const Onboarding = () => {
  const param = useParams();
  const [current, setCurrent] = useState(0);
  const [sideStepHide, setSideStepHide] = useState(false);
  const [getData, setGetData] = useState(null);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let clientId = localStorage.getItem("clientLeadId");
    if (clientId || param?.id) {
      if (current !== 2) {
        getClientDetails(clientId || param?.id);
      }
    }
    window.scrollTo(0, 0);
  }, [current]);

  const getClientDetails = async (clientId) => {
    let response = await API.Api(
      GET_CLIENT_LEAD_ID + `${clientId}`
    ).getWithPremiseHeader();

    if (response.status) {
      let data = response?.response?.data;
      setGetData(data);
      // if (data?.clientOnboard?.status === "Rejected") {
      //   setCurrent(1);
      // } else if (data?.clientDocument?.length > 0) {
      //   setCurrent(4);
      // } else if (data?.ratecard) {
      //   setCurrent(3);
      // } else if (data?.pincodeMapping) {
      //   setCurrent(2);
      // } else if (
      //   data?.clientOrganizationDetails?.contactPersonName !== null &&
      //   data?.clientOrganizationDetails?.contactPersonName !== undefined
      // ) {
      //   setCurrent(1);
      // }
    } else {
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const patchClient = async (values) => {
    setLoading(true);
    let clientLeadId = localStorage.getItem("clientLeadId");
    let response = await API.Api(
      UPDATE_LEAD_CLIENT_DETAILS + `${clientLeadId}`
    ).patchCustomHeader(values, {
      "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
    });
    if (response?.status) {
      setLoading(false);
      message.success("Data added successfully.");
      setCurrent(current + 1);
    } else {
      setLoading(false);
    }
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleStepsChange = () => {
    setCurrent(current + 1);
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    setCurrent(current - 1);
  };

  return (
    <div className="master-main-container">
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <Row
          className={sideStepHide ? "" : "p-1"}
          style={{ height: "70vh", overflow: "scroll" }}>
          {!sideStepHide && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={6}
              className="global-steps-container">
              <Steps
                current={current}
                direction="vertical"
                onChange={onChange}
                items={[
                  {
                    title: "Organisation Details",
                    description: "Please provide your org details",
                  },
                  {
                    title: "Business Details",
                    description: "Please provide your business details",
                    disabled:
                      getData?.businessDetails?.contactPersonName !== null &&
                      getData?.businessDetails?.contactPersonName !== undefined
                        ? false
                        : true,
                  },
                  {
                    title: "Pincode Mapping",
                    description: "Please create a PIN Code map",
                    disabled:
                      getData?.documentDetails?.startDate !== null &&
                      getData?.documentDetails?.startDate !== undefined
                        ? false
                        : true,
                  },

                  {
                    title: "Agreement Details",
                    description: "Please upload your document",
                    disabled:
                      getData?.documentDetails?.startDate !== null &&
                      getData?.documentDetails?.startDate !== undefined
                        ? false
                        : true,
                  },
                ]}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={sideStepHide ? 24 : 18}>
            {current === 0 ? (
              <OrganisationDetails
                handleStepsChange={handleStepsChange}
                patchClient={patchClient}
                getData={getData}
                goBackStep={goBackStep}
              />
            ) : current === 1 ? (
              <BusinessDetails
                handleStepsChange={handleStepsChange}
                patchClient={patchClient}
                getData={getData}
                goBackStep={goBackStep}
              />
            ) : current === 2 ? (
              <PincodeMapping
                handleStepsChange={handleStepsChange}
                goBackStep={goBackStep}
                getData={getData}
              />
            ) : current === 3 ? (
              <AddAgreement
                handleStepsChange={handleStepsChange}
                getData={getData}
                goBackStep={goBackStep}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Onboarding;
