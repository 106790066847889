import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import ViewCashBookingTable from "./cashBookingTable";
import ViewManifestTable from "./ViewManifest";
import { clearGetManifestTable } from "../../../actionsMethods/manifestMethods";

const ViewBookingTabs = () => {
  const history = useLocation();
  const dispatch = useDispatch();
  const [key, setKey] = useState("1");

  const onChange = (key) => {
    if (+key === 2) {
      dispatch(clearGetManifestTable());
    }
    setKey(key);
  };
  useEffect(() => {
    if (
      history?.state === "VIEW_MANIFEST" ||
      history?.state === "PRINT_MANIFEST"
    ) {
      setKey("2");
    } else {
      setKey("1");
    }
  }, [history]);

  return (
    <Tabs
      defaultActiveKey={"1"}
      activeKey={key}
      items={[
        {
          label: "View Booking",
          key: "1",
          children: <ViewCashBookingTable />,
        },
        {
          label: "View Manifest",
          key: "2",
          children: <ViewManifestTable />,
        },
      ]}
      onChange={onChange}
    />
  );
};

export default ViewBookingTabs;
