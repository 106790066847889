import React from "react";
import { useLocation, useNavigate } from "react-router";
import CreateTemplate from "./createTemplate";
//import Heading from "../../../../components/Heading";
import back_img from "../../../../assests/images/rightVector.svg";
import "./index.scss";
import "../../index.scss";
const AllocateTemplate = () => {
  const navigate = useNavigate();
  const history = useLocation();
  
  return (
    <div className="p-2-tab">
      {/* <Heading title={history?.state + " Template"} /> */}
      <p className="master-title">
        <img src={back_img} onClick={()=>navigate("/cash-booking-ratecard", {state: "allocate"})} className="pointer"/>
        <span>{history?.state?.name + " Template"}</span>
      </p>
      <CreateTemplate templateType={history?.state?.name} templateId={history?.state?.id} allocateStatus={history?.state?.allocateStatus}/>
    </div>
  );
};
export default AllocateTemplate;
