/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import API from "../../../api";
import urls from "../../../api/urls";
import {
  clearCreatedManifest,
  createManifest,
  getPacketListBySatus,
  getPacketListClear,
  //getViewAllBookings,
} from "../../../actionsMethods/manifestMethods";
import {
  getStartAndEndDate,
  dateFormat_dmy,
  capitaliseWithHyphen,
} from "../../../utils";
import {
  Col,
  Row,
  Select,
  Spin,
  Input,
  Button,
  message,
  DatePicker,
} from "antd";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DraggerModal from "../modals/draggerModal";
import ViewBookingSummaryEdit from "../../ViewBookingSummaryEdit";
import ViewBookingSummary from "../../ViewBookingSummary";
import {
  getBarcodeId,
  getSingleBooking,
} from "../../../actionsMethods/cashBookingMethods";
import { column } from "./column";
import TableContainer from "../../../components/TableContainer";
import search_icon from "../../../assests/images/search-icon.svg";
import truck from "../../../assests/images/SVG/packetSurface.svg";
import plane from "../../../assests/images/planeicon.svg";
import "./cashBookingTable.scss";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const {
  UPLOAD_MANIFEST_FILE,
  GET_RETAIL_DOCUMENT_DETAILS,
  GET_ALL_BOOKING_STATUS,
} = urls;
const { RangePicker } = DatePicker;

const BookingTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let premiseId = JSON.parse(localStorage.getItem("userId"));
  const [data, setData] = useState([]);
  const [oldAwbNum, setOldAwbNum] = useState(null);
  const [uploadModalvisible, setUploadModalvisible] = useState(false);
  const [editTest, setEditTest] = useState(false);
  const [manifestFor, setManifestFor] = useState();
  const [dataSource, setDataSource] = useState(data);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [dropdownValue, setdropdownValue] = useState(true);
  const [eyeButtonState, setEyeButtonState] = useState(false);
  const [inputState, setInputState] = useState(true);
  const [bookingType, setBookingType] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [typeBooking, setTypeBooking] = useState("Both");
  const [dateRange, setDateRange] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [bookingStatusList, setBookingStatusList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState("All");
  const typeBookingList = [
    {
      value: "Cash",
      label: "Retail",
    },
    {
      value: "Account",
      label: "Credit",
    },
    {
      value: "Both",
      label: "Both",
    },
  ];
  const { result } = useSelector(
    (state) => state.appReducer.getBarcodeIdReducer,
    shallowEqual
  );

  const viewBookingSummaryResponse = useSelector(
    (state) => state.appReducer.getSingleBookingReducer,
    shallowEqual
  );

  const viewBookingTableResponse = useSelector(
    (state) => state.manifestReducer.getViewAllBookingsReducer,
    shallowEqual
  );

  const createManifestResponse = useSelector(
    (state) => state.manifestReducer.createManifestReducer,
    shallowEqual
  );

  const { result: manifestResult, error: manifestError } =
    createManifestResponse;
  const { result: viewTableResult, fetching } = viewBookingTableResponse;

  const { fetching: loader1, result: packetAndShipment } = useSelector(
    (state) => state.manifestReducer.getPacketListBySatusReducer,
    shallowEqual
  );

  useEffect(() => {
    if (loader1 == false) {
      if (
        packetAndShipment?.packetsdto?.length > 0 ||
        packetAndShipment?.shipmentDto?.length > 0
      ) {
        setdropdownValue(false);
      } else {
        setdropdownValue(true);
      }
    }
  }, [packetAndShipment]);

  useEffect(() => {
    if (manifestError) {
      message.error(manifestError.message);
    } else if (manifestResult) {
      if (manifestFor == "Surface") {
        navigate("/child-scan");
        setdropdownValue(true);
      } else {
        // navigate("/viewBooking-packet");
        navigate("/child-scan");

        setdropdownValue(true);
      }

      localStorage.setItem("manifestFor", manifestFor.toLowerCase());
      localStorage.setItem("manifestedId", JSON.stringify(manifestResult));
    }
    return () => {
      dispatch(clearCreatedManifest());
    };
  }, [manifestResult, manifestError]);

  useEffect(() => {
    if (viewTableResult) {
      let data = viewTableResult?.viewBookings?.map((obj) =>
        obj.bookingStatus === "COMPLETED"
          ? { ...obj, bookingStatus: "BOOKED" }
          : obj
      );
      let data2 = { ...viewTableResult, viewBookings: data };
      setData(data2);
    }
  }, [viewTableResult]);

  useEffect(() => {
    getAllBookings(
      pageNo,
      pageSize,
      null,
      null,
      typeBooking,
      searchValue,
      bookingStatus
    );
    getAllBookingStatus();
    dispatch(getPacketListClear());
  }, []);

  const getAllBookingStatus = async () => {
    let response = await API.Api(GET_ALL_BOOKING_STATUS).getWithAllHeaders();
    if (response?.status) {
      let data = response?.response?.data?.map((e) => ({
        label: capitaliseWithHyphen(e === "COMPLETED" ? "BOOKED" : e),
        value: e === "COMPLETED" ? "COMPLETED" : e,
      }));
      setBookingStatusList([
        {
          label: "All",
          value: "All",
        },
        ...data?.sort((a, b) => a.value.localeCompare(b.value)),
      ]);
    }
  };

  const getAllBookings = async (
    pageNo,
    pageSize,
    startDate,
    endDate,
    type,
    search,
    status
  ) => {
    let searchData = search ? `&search=${search}` : "";
    let response = await API.Api(
      GET_RETAIL_DOCUMENT_DETAILS +
        `?pageNo=${pageNo}&pageSize=${pageSize}` +
        getStartAndEndDate(
          startDate ? startDate : null,
          endDate ? endDate : null,
          dateFormat_dmy
        ) +
        "&bookingType=" +
        type +
        searchData +
        "&status=" +
        status
    ).getWithAllHeaders();
    if (response?.status) {
      let res = response?.response?.data;
      let temp = res?.viewBookings?.map((obj) =>
        obj.bookingStatus === "COMPLETED"
          ? { ...obj, bookingStatus: "BOOKED" }
          : obj
      );

      let response1Data = { ...res, viewBookings: temp };
      let result = sanitizeData(response1Data);

      setData(result);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  function sanitizeData(obj) {
    for (let key in obj) {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        Number.isNaN(obj[key]) ||
        obj[key] === "NaN" ||
        Number.isNaN(obj[key])
      ) {
        obj[key] = 0;
      }
    }
    return obj;
  }

  const handleUpload = useCallback((val) => {
    setUploadModalvisible(true);
    setOldAwbNum(val.awbNumber);
  });

  function handleCancel() {
    setIsModalOpen(false);
    setIsSummaryModalOpen(false);
    setEditTest(true);
    setInputState(true);
  }

  function MyComponent(dateToFormat) {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 10);
    };
    return (
      <Moment filter={toUpperCaseFilter} format="DD/MM/YYYY">
        {dateToFormat}
      </Moment>
    );
  }

  function handleEditView(params) {
    setBookingType(params?.bookingType);
    setIsModalOpen(true);
    dispatch(getSingleBooking(params?.awbNumber));
    setBookingType(params?.bookingType);
    setEditTest(true);
  }

  const handleEyeButton = (b) => {
    setBookingType(b?.bookingType);
    setIsSummaryModalOpen(true);
    dispatch(getSingleBooking(b?.awbNumber));
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const statusFun = (val) => {
    const statusColors = {
      BOOKED: "stat-green",
      INITIATED: "blue",
      "In progress": "blue",
      Delivered: "stat-green",
      PACKETED: "darkBlue",
      SCANNED: "purple",
      "In transit": "yellow",
      MANIFESTED: "yellow",
      OUTSCANNED_TO_CP: "yellow",
    };

    const color = statusColors[val] || "";

    return <p className={color}>{val}</p>;
  };

  function handleEdit(val) {
    const serviceTypeMap = {
      "Fast Track": "2",
      "Value Plus": "3",
      Standard: "1",
    };

    const sType = serviceTypeMap[val?.serviceType] || "1";
    setOldAwbNum(val.awbNumber);
    dispatch(getBarcodeId(sType, premiseId?.userInfo?.premiseId));
  }

  const filterArray = (dataSource, currValue) =>
    dataSource?.viewBookings.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  /******************* Input search ******************************/
  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
    getAllBookings(
      0,
      10,
      dateRange?.startDate,
      dateRange?.endDate,
      typeBooking,
      e.target.value,
      bookingStatus
    );
    // const currValue = e.target.value;
    // setInputSearchValue(currValue);
    // const filteredData =
    //   inputSearchValue.length > 0 || inputSearchValue.length == 0
    //     ? filterArray(data, currValue)
    //     : filterArray(data, currValue);
    // setDataSource(filteredData);
  };

  /******************* Type ******************************/

  function closeUploadModal(data) {
    setUploadModalvisible(false);
  }

  async function handleOK(data) {
    const temp = data?.map((val) => ({
      fileName: val?.response?.data?.filePath,
      fileId: val?.response?.data?.id,
    }));

    let response = await API.Api(UPLOAD_MANIFEST_FILE).postCustomHeader(temp, {
      "Awb-Number": oldAwbNum,
    });
    if (response) {
      setUploadModalvisible(false);
    }
  }

  function handlePageChange(pagenum, pagesizee) {
    setPageNo(pagenum - 1);
    setPageSize(pagesizee);
    getAllBookings(
      pagenum - 1,
      pagesizee,
      dateRange?.startDate,
      dateRange?.endDate,
      typeBooking,
      searchValue,
      bookingStatus
    );
    window.scrollTo(0, 0);
  }

  const handleGenerateManifest = () => {
    let manifestpayload = {
      type: "pickup",
      status: "INITIATED",
      // pickupDriverId: "null",
      travelBy: manifestFor.toUpperCase(),
    };
    dispatch(createManifest(manifestpayload));
  };

  const handleChange = (item) => {
    const newItem = item;

    const actionMap = {
      Surface: () => {
        setManifestFor("Surface");
        dispatch(getPacketListBySatus("manifest", "surface"));
      },
      Both: () => {
        setManifestFor("BOTH");
        setdropdownValue(true);
      },
      Air: () => {
        setManifestFor("Air");
        dispatch(getPacketListBySatus("manifest", "air"));
      },
    };

    const action = actionMap[newItem];
    if (action) {
      action();
    }
  };

  /******************* DATE AND TYPE FILTER ******************************/

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDateChange = (e, date) => {
    setDateRange({ startDate: date[0], endDate: date[1] });
    getAllBookings(
      0,
      10,
      date[0],
      date[1],
      typeBooking,
      searchValue,
      bookingStatus
    );
  };

  /******************* DATE AND TYPE FILTER END ******************************/

  return (
    <Spin
      spinning={fetching}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <ViewBookingSummaryEdit
        visible={isModalOpen}
        handleCancel={handleCancel}
        allAppData={viewBookingSummaryResponse?.result}
        editTest={editTest}
        setEditTest={setEditTest}
        setIsModalOpen={setIsModalOpen}
        setInputState={setInputState}
        inputState={inputState}
        eyeButtonState={eyeButtonState}
        setEyeButtonState={setEyeButtonState}
        bookingType={bookingType}
      />
      <ViewBookingSummary
        visible={isSummaryModalOpen}
        handleCancel={handleCancel}
        allAppData={viewBookingSummaryResponse?.result}
        bookingType={bookingType}
      />

      <DraggerModal
        oldAwbNum={oldAwbNum}
        visible={uploadModalvisible}
        handleCancel={closeUploadModal}
        handleOk={handleOK}
      />

      <div className="table-container">
        <Row justify="space-between" className="row-one align-center">
          <Col lg={8} md={6} sm={6}>
            <p className="label">Shipment count and weight</p>
          </Col>

          <Col span={6}>
            <Input
              placeholder="Search by document No, mode"
              bordered
              onChange={(e) => handleChangeSearch(e)}
              style={{
                borderRadius: "5px",
                height: "40px",
                marginRight: "10px",
              }}
              suffix={<img src={search_icon} />}
            />
          </Col>
          {/* <Col lg={16} md={18} sm={18} className="generate-manifest">
            <Button
              type="primary"
              // onClick={() => setOpen(true)}
              // // disabled={dropdownValue}
              onClick={handleGenerateManifest}
              disabled={dropdownValue}
              className="addPacketBtn"
            >
              GENERATE MANIFEST
            </Button>
          </Col> */}
        </Row>

        <Row className="filter-container space-between">
          <Col className="standard" span={3}>
            <div>
              <img src={truck} />
              <span>
                {+data?.surfaceShipmentNo}/
                {+data?.surfaceShipmentWeight?.toFixed(2)} {" kg"}
              </span>
            </div>
          </Col>
          <Col className="fast-track" span={3}>
            <div>
              <img src={plane} />
              <span>
                {+data?.airShipmentNo}/{+data?.atrShipmentWeight?.toFixed(2)}
                {" kg"}
              </span>
            </div>
          </Col>
          <Col className="stand-fast" span={4}>
            <div>
              <img src={truck} /> + <img src={plane} />
              <span className="span-2">
                {/* Consolidated weight :{" "} */}
                {+data?.airShipmentNo + +data?.surfaceShipmentNo}/
                {+data?.atrShipmentWeight +
                  +data?.surfaceShipmentWeight?.toFixed(2)}
                {" kg"}
              </span>
            </div>
          </Col>
          {/* <Col className="select-type">
            <Select
              placeholder="Select mode"
              onChange={handleChange}
              options={[
                {
                  value: "Surface",
                  label: "Surface",
                },
                {
                  value: "Air",
                  label: "Air",
                },
                {
                  value: "Both",
                  label: "Both",
                },
              ]}
            />
          </Col> */}
          <Col span={6}>
            <RangePicker
              defaultValue={[
                moment(new Date(), dateFormat_dmy),
                moment().subtract(1, "days"),
                dateFormat_dmy,
              ]}
              disabledDate={(current) => disabledDate(current)}
              onChange={handleDateChange}
              format={dateFormat_dmy}
            />
          </Col>
          <Col span={6}>
            <Row justify={"space-between"} style={{ paddingRight: "12px" }}>
              <Col span={12}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  className="filter-select"
                  value={bookingStatus}
                  onChange={(val) => {
                    setBookingStatus(val);
                    getAllBookings(
                      0,
                      10,
                      dateRange?.startDate,
                      dateRange?.endDate,
                      typeBooking,
                      searchValue,
                      val
                    );
                  }}
                  options={bookingStatusList}
                />
              </Col>
              <Col span={2}></Col>

              <Col span={8}>
                <Select
                  defaultValue="both"
                  className="filter-select mr-2"
                  style={{ width: "104%" }}
                  value={typeBooking}
                  onChange={(val) => {
                    setTypeBooking(val);
                    getAllBookings(
                      0,
                      10,
                      dateRange?.startDate,
                      dateRange?.endDate,
                      val,
                      searchValue,
                      bookingStatus
                    );
                  }}
                  options={typeBookingList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <TableContainer
          columns={column(
            MyComponent,
            statusFun,
            handleEdit,
            handleEditView,
            handleUpload,
            handleEyeButton
          )}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pagination={{
            pageSize: pageSize,
            total:
              inputSearchValue.length == 0
                ? data?.totalCount
                : dataSource.length,
            onChange: handlePageChange,
            itemRender: itemRender,
          }}
          total={data?.totalCount}
          defaultCurrent={1}
          dataSource={
            inputSearchValue.length == 0 ? data?.viewBookings : dataSource
          }
          headerClassName="header"
          className="view-table table-head-sticky"
        />
      </div>
    </Spin>
  );
};

export default React.memo(BookingTable);
