import React from "react";
import {
  Col,
  Form, Select, DatePicker, Row
} from "antd";
import moment from "moment";

const AllocateForm = ({ getCategoryValueOption, categoryBasedOptions, searchValue, allocationFromDate, allocationToDate, disabled, templateType, changeFromDate, changeToDate, setExpiryDate, selectCategoryBasedValue}) => {
 
  const dateFormat = "DD-MM-YYYY";
  const disabledDate = (current) => {
    return  current < moment().startOf("day");
  };

  const disabledDateNext = (current) => {
    return  current <= moment(allocationFromDate, "DD-MM-YYYY").endOf("day");
  };

  const changeAllocationFrom = (date, dateString) =>{
    changeFromDate(date ,dateString);
  };
 
  return <Row gutter={40}>
    <Col
      className="form-inputs"
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      offset={1}
    >
      <Form.Item name="category" label="Select Category" rules={ templateType !== "view" && [
        {
          required: true,
          message: "Please select category",
        }]}>
        <Select
          placeholder="Select category"
          options={[
            {
              value: "zone",
              label: "Zone",
            },
            {
              value: "state",
              label: "State",
            },
            {
              value: "hub",
              label: "Hub",
            },
            {
              value: "cp",
              label: "CP",
            },
          ]}
          onChange={(e) => getCategoryValueOption(e)} 
          disabled={disabled}/>
      </Form.Item>
    </Col>
    <Col
      className="form-inputs"
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      offset={1}
    >
      <Form.Item name="value" label="Select Value" rules={ templateType !== "view" && [
        {
          required: true,
          message: "Please select value",
        }]}>
        <Select
          placeholder="Select value"
          options={categoryBasedOptions}
          mode="multiple"
          onSearch={(e) => searchValue(e)} 
          onChange={selectCategoryBasedValue}
          disabled={disabled}/>
      </Form.Item>
    </Col>
    <Col
      className="form-inputs"
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      offset={1}
    >
      <Form.Item name="fromDate" label="Allocation From Date" initialValue={moment(allocationFromDate, dateFormat)} rules={ templateType !== "view" && [
        {
          required: true,
          message: "Please select allocation from date",
        }]}>
        <DatePicker disabled={disabled} disabledDate={disabledDate}  format={dateFormat} onChange={(date, dateString)=>changeAllocationFrom(date, dateString)}/>
      </Form.Item>
    </Col>
    <Col
      className="form-inputs"
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      offset={1}
    >
      <Form.Item name="toDate" label="Allocation To Date" initialValue={moment(allocationToDate, dateFormat)} rules={ templateType !== "view" && [
        {
          required: true,
          message: "Please select allocation to date",
        }]}>
        <DatePicker disabled={disabled} disabledDate={disabledDateNext} format={dateFormat} onChange={changeToDate}/>
      </Form.Item>
    </Col>
    <Col
      className="form-inputs"
      xs={24}
      sm={24}
      md={12}
      lg={12}
      xl={12}
      offset={1}
    >
      <Form.Item name="expiryThreshold" label="Expiry alert threshold (in days)" rules={ templateType !== "view" && [
        {
          required: true,
          message: "Please select expiry alert threshold",
        }]}>
        <Select
          placeholder="Select expiry alert threshold"
          options={[
            {
              value: "15",
              label: "15",
            },
            {
              value: "30",
              label: "30",
            },
            {
              value: "45",
              label: "45",
            },
            {
              value: "60",
              label: "60",
            },
          ]}
          onChange={setExpiryDate} 
          disabled={disabled}/>
      </Form.Item>
    </Col>
  </Row>;
};

export default AllocateForm;
