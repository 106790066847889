/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import "./style.scss";
import ManifestList from "./ManifestList";
import { Row, Col, Button, Spin, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getPacketListBySatus,
  patchPacketShipment,
  patchManifestScan,
  patchPacketShipmentClear,
} from "../../../actionsMethods/manifestMethods";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

function ScanedandManifestList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let barcode = "";
  let interval;
  const [manifestListArr, setManifestListArr] = useState([]);
  const [manifestFor, setManifestFor] = useState();

  const { fetching: loader1, result: packetAndShipment } = useSelector(
    (state) => state.manifestReducer.getPacketListBySatusReducer,
    shallowEqual
  );

  // const scandatadata = manifestListArr
  //   .filter((el) => el?.status == "SCANNED")
  //   .map((el) => el?.childShipmentDetails?.map((d) => d?.childAwbNumber));

  const {
    fetching: loader3,
    result: patchPacketShipmentResult,
    error: patchPacketShipmentError,
  } = useSelector(
    (state) => state.manifestReducer.patchPacketShipmentReducer,
    shallowEqual
  );

  const { fetching: loader2, error: manifestedError } = useSelector(
    (state) => state.manifestReducer.patchManifestScanReducer,
    shallowEqual
  );

  useEffect(() => {
    if (patchPacketShipmentResult) {
      navigate("/truck");
    }

    if (patchPacketShipmentError) {
      message.error(patchPacketShipmentError?.message);
    }

    return () => {
      dispatch(patchPacketShipmentClear());
    };
  }, [patchPacketShipmentError, patchPacketShipmentResult]);

  useEffect(() => {
    if (manifestedError !== null) {
      message.error(manifestedError?.message);
    }
  }, [manifestedError]);

  useEffect(() => {
    setManifestFor(localStorage.getItem("manifestFor"));
  }, [manifestFor]);

  useEffect(() => {
    if (manifestFor) {
      dispatch(getPacketListBySatus("manifest", manifestFor));
    }
  }, [loader2, manifestFor]);

  useEffect(() => {
    if (loader1 == false && loader2 == false) {
      if (packetAndShipment !== null) {
        let arr = [
          ...packetAndShipment?.packetsdto,
          ...packetAndShipment?.shipmentDto,
        ];
        setManifestListArr(arr);
      }
    }
  }, [loader1, loader2]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleBarcode(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  const handleBarcode = (scanned_barcode) => {
    dispatch(patchManifestScan(scanned_barcode));
  };

  const onChangeHandler = (e) => {
    dispatch(patchManifestScan(+e.target.value));
  };

  const totalIndividual = (objKey, packetStatus) => {
    return manifestListArr?.reduce((counter, obj) => {
      if (Object.keys(obj).includes(objKey) && obj?.status === packetStatus)
        counter += 1;
      return counter;
    }, 0);
  };

  const totalChildPacket = () => {
    return manifestListArr?.reduce((count, r) => {
      if (
        Object.keys(r).includes("childShipmentDetails") &&
        r?.childShipmentDetails !== null &&
        r?.childShipmentDetails?.length !== 0
      ) {
        count = count + +r?.childShipmentDetails?.length;
      }
      return +count;
    }, 0);
  };

  const totalPacket = (objKey) => {
    return manifestListArr.reduce((counter, obj) => {
      if (Object.keys(obj).includes(objKey)) counter += 1;
      return counter;
    }, 0);
  };

  let finalized = totalIndividual("packetBarcode", "FINALISED");
  let completed = totalIndividual("awbNumber", "COMPLETED");
  let isDisabled = finalized == 0 && completed == 0 ? false : true;

  return (
    <Spin spinning={loader1 || loader3 || loader2}>
      <Row
        style={{
          position: "sticky",
          top: "80px",
          zIndex: "1",
          width: "97%",
          margin: "auto",
        }}
      >
        <Input
          onChange={(e) => onChangeHandler(e)}
          placeholder="Enter AWB number to scan"
        />
      </Row>
      <Row>
        <Col lg={12}>
          <ManifestList
            name={"Manifest List"}
            packet={finalized}
            individual={completed}
            color={"yello"}
            packetAndShipmate={manifestListArr}
            packetStatus={"FINALISED"}
            shipmentStatus={"COMPLETED"}
            manifestFor={manifestFor}
          />
        </Col>

        <Col lg={12}>
          <ManifestList
            name={"Scanned Manifest List"}
            // packet={`${totalIndividual(
            //   "packetBarcode",
            //   "SCANNED"
            // )} / ${totalPacket("packetBarcode")}`}
            // individual={`${totalIndividual(
            //   "awbNumber",
            //   "SCANNED"
            // )} / ${totalPacket("awbNumber")}`}
            color={"greens"}
            packetAndShipmate={manifestListArr}
            packetStatus={"SCANNED"}
            shipmentStatus={"SCANNED"}
            manifestFor={manifestFor}
            // totalshipment={
            //   totalPacket("packetBarcode") + totalPacket("awbNumber")
            // }
            totalScannedShipment={
              totalIndividual("awbNumber", "SCANNED") +
              totalIndividual("packetBarcode", "SCANNED")
            }
          >
            <NavigateButton
              manifestListArr={manifestListArr}
              packetNum={totalPacket("packetBarcode")}
              shipmentNum={totalPacket("awbNumber")}
              scannedPacket={totalIndividual("packetBarcode", "SCANNED")}
              scannedShipment={totalIndividual("awbNumber", "SCANNED")}
              isDisabled={isDisabled}
              totalChildPacket={totalChildPacket()}
            />
          </ManifestList>
        </Col>
      </Row>
    </Spin>
  );
}

const NavigateButton = ({
  manifestListArr,
  packetNum,
  shipmentNum,
  isDisabled,
  totalChildPacket,
}) => {
  const dispatch = useDispatch();
  const [manifestId, setManifestId] = useState();

  useEffect(() => {
    let data = localStorage.getItem("manifestedId");
    setManifestId(JSON.parse(data));
  }, []);

  const readyToLoad = () => {
    const totalWeight = (objKey, packetStatus) => {
      return manifestListArr?.reduce((counter, obj) => {
        if (Object.keys(obj).includes(objKey) && obj?.status === packetStatus) {
          if (obj?.childShipmentDetails?.length !== 0) {
            obj?.childShipmentDetails?.forEach(
              (we) => (counter += we?.volumetricWeight)
            );
          }
          counter += obj.volumetricWeight;
        }
        return counter;
      }, 0);
    };
    const totalVolWeight = (objKey, packetStatus) => {
      return manifestListArr?.reduce((counter, obj) => {
        if (Object.keys(obj).includes(objKey) && obj?.status === packetStatus) {
          if (obj?.childShipmentDetails?.length !== 0) {
            obj?.childShipmentDetails?.forEach((we) => (counter += we?.weight));
          }
          counter += obj.weight;
        }
        return counter;
      }, 0);
    };

    let allManifestShipment = manifestListArr
      .filter((el) => el?.status == "SCANNED")
      .map((el) => ({
        awbNumber: el?.awbNumber || el?.packetBarcode,
        status: "ADDED",
        type: null,
        weight: el?.weight,
        childShipmentDetails: el?.childShipmentDetails,
      }));

    let patchData = {
      totalVolWeight: totalWeight("weight", "SCANNED").toFixed(2),
      shipmentNum: shipmentNum + totalChildPacket,
      packetNum: packetNum,
      status: "UNLOCKED",
      manifestShipment: allManifestShipment,
      totalweight: totalVolWeight("weight", "SCANNED").toFixed(2),
    };
    dispatch(patchPacketShipment(patchData, manifestId?.manifestId));
  };

  return (
    <>
      <div className="scan-container-header-line"></div>

      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}
        className
      >
        {" "}
        <Button
          className="navigate-button"
          onClick={readyToLoad}
          disabled={isDisabled}
        >
          ready to load
        </Button>
      </div>
    </>
  );
};

export default ScanedandManifestList;
