import API from "../../../api";
import { APIs } from "../../../utils/worker";
import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Switch,
  Spin,
  Tooltip,
  Select,
  Input,
  message,
  Row,
  Col,
  Modal,
} from "antd";
import "./style.scss";
import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import { ReactComponent as Download_pin_icon } from "../../../assests/images/download.svg";
import { capitalize, camelCase } from "../../../utils";

function ViewProudct({ onChangekey, handleEdit = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [updateOrAddCategory, setUpdateOrAddCategory] = useState(false);
  const [categoryArr, setCategoryArr] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));

  const [modalData, setModalData] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  useEffect(() => {
    getTableData();
    if (categoryArr.length === 0) {
      categoryList();
    }
  }, [updateOrAddCategory]);

  const categoryList = async () => {
    let response = await API.Api(
      `${APIs.baseURL}/categories`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setCategoryArr(response?.response?.data);
    }
  };

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/item`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      setTableData(response?.response?.data);
    } else {
      setLoading(false);
    }
  };

  const dowonloadList = async () => {
    window.open(`${APIs.baseURL}/item/v1/item-download`);
  };

  const changeStatus = async (val, checked) => {
    if (checked?.stateWisePriceDto.length == 0) {
      handleEdit(checked);
    } else {
      let response = await API.Api(
        `${APIs.baseURL}/item/v1/update-product-status/${checked?.id}`
      ).put({
        restrictions: !val,
      });

      if (response.status) {
        setUpdateOrAddCategory(!updateOrAddCategory);
        // setLoading(false);
      } else {
        // setLoading(false);
        message.error(response?.response?.data?.message || "Network error");
      }
    }
  };

  const { Option } = Select;

  const openModal = (data, name) => {
    setModalData({
      show: true,
      list: data,
      name,
    });
  };
  const cancelBoxModal = () => {
    setModalData({
      show: false,
      currentBox: null,
      name: null,
    });
  };
  const columns = [
    {
      title: "Product name",
      dataIndex: "productName",
      key: "productName",
      render: (text, record) => (
        <div className="product-name-col ">
          <img src={record?.imageDtos[0]?.imagePath} alt="product image" />
          <div>
            <h4 style={{ margin: "0" }}>{capitalize(record?.name)}</h4>
            <p style={{ margin: "0" }}>{capitalize(record?.categoryName)}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Default price",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <span
          style={{
            fontSize: "13px",
            color: "#272C39",
          }}
        >
          ₹{text}
        </span>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "State",
      dataIndex: "stateWisePriceDto",
      key: "stateWisePriceDto",
      render: (text) => (
        <>
          {text.length !== 0 ? (
            <div style={{ display: "flex" }}>
              {text?.slice(0, 1).map((e, i) => (
                <div key={i}>
                  <span>
                    {capitalize(e?.stateName)} {i < 2 ? "" : ""}&nbsp;
                  </span>
                </div>
              ))}
              {text?.length > 2 && (
                <div className="tooltip-background">
                  <text
                    className="text-style"
                    onClick={() => openModal(text, "State name")}
                  >
                    {text?.length > 1 ? `+ ${text.length - 1}` : null}
                  </text>
                </div>
              )}
            </div>
          ) : (
            "--------"
          )}
        </>
      ),
    },
    {
      title: "CP",
      dataIndex: "cpNames",
      key: "cpNames",
      width: 250,
      render: (_, record) => (
        <>
          {record?.eligibilityName?.toUpperCase() == "BOTH" ||
          record?.eligibilityName?.toUpperCase() == "CP"
            ? "Yes"
            : "No"}
          {/* {record?.eligibilityName?.toUpperCase() == "BOTH" ? (
            "All"
          ) : record?.eligibilityName?.toUpperCase() == "CP" ? (
            <div style={{ display: "flex" }}>
              {text?.slice(0, 1).map((e, i) => (
                <div key={i}>
                  <span>
                    {capitalize(e)} {i < 2 ? " " : ""}&nbsp;
                  </span>
                </div>
              ))}

              {text.length > 2 && (
                <div className="tooltip-background">
                  <text
                    className="text-style"
                    onClick={() => openModal(text, "CP name")}
                  >
                    {text?.length > 1 ? `+ ${text.length - 1}` : null}
                  </text>
                </div>
              )}
            </div>
          ) : (
            "--------"
          )} */}
        </>
      ),
    },
    {
      title: "HUB",
      dataIndex: "hubNames",
      key: "hubNames",
      render: (_, record) => (
        <>
          {record?.eligibilityName?.toUpperCase() == "BOTH" ||
          record?.eligibilityName?.toUpperCase() == "HUB"
            ? "Yes"
            : "No"}
          {/* {text?.length !== 0 ? (
            record?.eligibilityName?.toUpperCase() == "BOTH" ? (
              "All"
            ) : record?.eligibilityName?.toUpperCase() == "HUB" ? (
              <div style={{ display: "flex" }}>
                {text?.slice(0, 1).map((e, i) => (
                  <div key={i}>
                    <span>
                      {capitalize(e)} {i < 2 ? "  " : ""}&nbsp;
                    </span>
                  </div>
                ))}
                {text.length > 2 && (
                  <div className="tooltip-background">
                    <text
                      className="text-style"
                      onClick={() => openModal(text, "HUB name")}
                    >
                      {text?.length > 1 ? `+ ${text.length - 1}` : null}
                    </text>
                  </div>
                )}
              </div>
            ) : (
              "--------"
            )
          ) : (
            "--------"
          )} */}
        </>
      ),
    },
    {
      title: "Published",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 200,
      render: (val) => {
        const date = new Date(val);
        const options = {
          month: "short",
          year: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return (
          <>
            <span>{formattedDate}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "restrictions",
      dataIndex: "restrictions",
      render: (val, record) => {
        return (
          <>
            <Switch
              className="switch-color"
              checked={val}
              onChange={() => changeStatus(val, record)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => {
        return (
          <div
            style={{ display: "flex", columnGap: "10px", paddingTop: "15px" }}
          >
            <Space size="middle">
              <Tooltip title={"Edit"} placement="bottom">
                <p className="pointer" onClick={() => handleEdit(record)}>
                  <Edit_icon />
                </p>
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const [filterData, setFilterData] = useState({
    categoryId: null,
    searchTerm: null,
    restrictions: null,
  });

  useEffect(() => {
    onSearch();
  }, [filterData]);

  const [filterdTable, setFilterdTable] = useState([]);

  const onSearch = async () => {
    let cpId = {};
    let header = {
      categoryId: filterData?.categoryId,
      searchTerm: filterData?.searchTerm,
      restrictions: filterData?.restrictions,
    };
    setLoading(true);
    if (userinfo.roles.includes("ROLE_CPADMIN")) {
      cpId = { cpId: userId?.userInfo?.premiseId };
    }

    let response = await API.Api(
      `${APIs.baseURL}/item/v1/searchItems?`
    ).getwithParams({ ...cpId, ...header });
    if (response.status) {
      setFilterdTable(response.response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="product-container">
      <Spin spinning={loading}>
        <div className="category-table-container">
          <div className="flex-end flex-col-gap m-1">
            <Button className="btn-add-category" onClick={dowonloadList}>
              <Download_pin_icon style={{ marginRight: "5px" }} /> DOWNLOAD
            </Button>
            <Button
              className="btn-add-category "
              onClick={() => onChangekey("2")}
            >
              + ADD PRODUCT
            </Button>
          </div>

          <div className="divider"></div>
          <Row
            className="pl-2 pr-2 p-0-5"
            style={{ paddingBottom: "10px" }}
            gutter={[10]}
            justify={"center"}
          >
            <Col
              lg={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>FILTER</div>
            </Col>
            <Col lg={6}>
              <Select
                placeholder="Category"
                className=" filter-input"
                onSelect={(e) =>
                  setFilterData({ ...filterData, categoryId: e })
                }
              >
                {categoryArr?.map((cat) => (
                  <>
                    <Option key={cat?.id} value={cat?.id}>
                      {camelCase(cat?.categoryName)}
                    </Option>
                  </>
                ))}
                <Option value={null}>All</Option>
              </Select>
            </Col>
            <Col lg={6}>
              <Select
                placeholder="Status"
                className=" filter-input"
                // onSelect={(e) => onSearch(null, null, e)}
                onSelect={(e) =>
                  setFilterData({ ...filterData, restrictions: e })
                } // style={{ borderRadius: "5px", height: "40px", width: "100%" }}
              >
                <Option value={true}>Enable</Option>
                <Option value={false}>Disable</Option>
                <Option value={null}>All</Option>
              </Select>
            </Col>

            <Col lg={8}>
              <Input
                placeholder="Product name, SKU"
                suffix={<img src={search_icon} />}
                className="filter-input"
                onSelect={(e) =>
                  setFilterData({
                    ...filterData,
                    searchTerm: e.target.value == "" ? null : e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={
              filterData?.categoryId == null &&
              filterData?.searchTerm == null &&
              filterData?.restrictions == null
                ? tableData
                : filterdTable
            }
            scroll={{
              x: 1500,
              y: 500,
            }}
            pagination={{
              itemRender: itemRender,
            }}
          />
        </div>

        <Modal
          title={modalData?.name}
          open={modalData?.show}
          onCancel={cancelBoxModal}
          width={700}
          footer={null}
          className="hub-show-modal"
        >
          <Row gutter={15} className="mb-1">
            {modalData?.list?.map((e) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  key={e.value}
                  className="mt-1"
                >
                  <div className="d-flex space-between p-05 modal-hub">
                    {!e?.stateName ? (
                      <span>{capitalize(e)}</span>
                    ) : (
                      <span>{capitalize(e?.stateName)}</span>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Modal>
      </Spin>
    </div>
  );
}

export default ViewProudct;
