/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Table, Space, Input, Tooltip, Spin } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCashRateCardTemplates } from "../../../../actionsMethods/masterMethods";
import { serialNum } from "../../../../utils";
import { APIs, getToken } from "../../../../utils/worker";
import axios from "axios";
import search_icon from "../../../../assests/images/search-icon.svg";
import edit_icon from "../../../../assests/images/edit-logo.svg";
import eye_icon from "../../../../assests/images/eyeIcon.svg";
import allocate from "../../../../assests/images/master/allocate.svg";
import disable_allocate from "../../../../assests/images/master/disable-allocate.svg";
import disable_edit from "../../../../assests/images/master/edit-disable.svg";
import { capitaliseWithHyphen } from "../../../../utils";
import "./index.scss";
import "../../index.scss";

const ViewTemplate = ({templateType, slug}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [totalMainPageSize, setTotalMainPageSize] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [searchLoad, setSearchLoad] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const cashRateCardTemplates = useSelector(
    (state) => state.masterReducer.getCashRateCardTemplatesReducer,
    shallowEqual
  );

  const { fetching: loading, result: cashRateCardResults } =
    cashRateCardTemplates;
  useEffect(() => {
    setPageNum(0);
    dispatch(getCashRateCardTemplates("?pageNo=0&pageSize=10"));
  }, [templateType]);

  useEffect(() => {
    if (cashRateCardResults) {
      let temp = [];
      setTotalPageSize(cashRateCardResults?.data?.totalCount);
      setTotalMainPageSize(cashRateCardResults?.data?.totalCount);
      cashRateCardResults?.data?.ratecards?.map((e, index) => {
        temp.push({
          key: index + 1,
          name: e.ratecardName,
          financialYear: e.financialYear,
          dateCreated: e.createdDate,
          allocated: e.isAllocated ? "Yes" : "No",
          id: e.id,
        });
      });
      setData(temp);
      setDataSource(temp);
    }
  }, [cashRateCardResults]);

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    // eslint-disable-next-line no-empty
    if (inputSearch.length > 0) {
    } else {
      dispatch(
        getCashRateCardTemplates(
          `?pageNo=${pagination.current - 1}&pageSize=${pagination.pageSize}`
        )
      );
    }
  }

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //align: "center",
      with: 15,
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
    },
    {
      title: "Financial Year",
      dataIndex: "financialYear",
      key: "financialYear",
      //align: "center",
      with: 15,
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      //align: "center",
      with: 15,
    },
    {
      title: "Allocated",
      dataIndex: "allocated",
      key: "allocated",
      //align: "center",
      with: 15,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 30,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={record.allocated === "Yes" ? "" : "Edit"}>
            <img
              src={record.allocated === "Yes" ? disable_edit : edit_icon}
              alt="edit"
              onClick={() =>
                record.allocated === "No" &&
                navigate(slug === "/account-booking-ratecard" ? "/account-create-template" : "/allocate-template", {
                  state: { name: "edit", id: record.id },
                })
              }
              className={
                record.allocated === "Yes"
                  ? "w-33 pointer-disable"
                  : "w-33 pointer"
              }
            />
          </Tooltip>
          <Tooltip title={record.allocated === "Yes" ? "" : "View"}>
            <img
              src={eye_icon}
              alt="view"
              onClick={() =>
                navigate(slug === "/account-booking-ratecard" ? "/account-create-template" : "/allocate-template", {
                  state: {
                    name: "view",
                    id: record.id,
                    allocateStatus: record.allocated,
                  },
                })
              }
              className="w-33 pointer"
            />
          </Tooltip>
          <Tooltip title={record.allocated === "Yes" ? "" : "Allocate"}>
            <img
              src={record.allocated === "Yes" ? disable_allocate : allocate}
              alt="allocate"
              onClick={() =>
                record.allocated === "No" &&
                navigate(slug === "/account-booking-ratecard" ? "/account-create-template" : "/allocate-template", {
                  state: { name: "allocate", id: record.id },
                })
              }
              className={
                record.allocated === "Yes"
                  ? "w-33 pointer-disable"
                  : "w-33 pointer"
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const onSearch = async (e) => {
    if (e.target.value.length < 3) {
      setPageNum(0);
    }
    setInputSearch(e.target.value);
    if (e.target.value.length >= 2) {
      setSearchLoad(true);
      try {
        let res = await axios.get(
          `${APIs.baseURL}/ratecard-service/v1/ratecard/search/${e.target.value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res?.data) {
          let temp = [];
          res?.data?.data?.map((e, index) => {
            temp.push({
              key: index + 1,
              name: e.ratecardName,
              financialYear: e.financialYear,
              dateCreated: e.createdDate,
              allocated: e.isAllocated ? "Yes" : "No",
              id: e.id,
            });
          });
          setData(temp);
          setTotalPageSize(res?.data?.data?.length);
        }
      } catch (error) {
        setData(data);
        setTotalPageSize(totalMainPageSize);
      }
      setSearchLoad(false);
    }
    if (e.target.value.length < 1) {
      dispatch(getCashRateCardTemplates("?pageNo=0&pageSize=10"));
      setTotalPageSize(totalMainPageSize);
    }
  };
  return (
    <Spin spinning={loading || searchLoad}>
      <div
        className="template-container"
        style={{
          overflow: "inherit",
        }}>
        <div className="text-right p-1">
          <Input
            placeholder="Search by (template name / financial year)"
            bordered
            onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "400px" }}
            suffix={<img src={search_icon} />}
          />
        </div>
        <Table
          className="table-head-sticky"
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: pageSize,
            total: totalPageSize,
            itemRender: itemRender,
            current: pageNum == 0 ? +1 : pageNum,
          }}
          onChange={handlePageChange}
        />
      </div>
    </Spin>
  );
};

export default ViewTemplate;
